import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setUser } from "../reducers/userReducer";
import { getDirectusBaseUrl } from "../utils/envUtils";

export const menuApi = createApi({
  reducerPath: "menuApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: getDirectusBaseUrl(),
  }),
  tagTypes: ["Menu"],
  endpoints: (builder) => ({
    fetchMenu: builder.mutation({
      query: () =>
        "items/menu?fields[]=isMain%2Clabel%2Clink%2Cid%2Csub_menu.related_menu_id.label%2Csub_menu.related_menu_id.link%2Csub_menu.related_menu_id.id&filter[isMain][_eq]=true",
      transformResponse: (result: { data: any }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useFetchMenuMutation } = menuApi;
