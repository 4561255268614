// Map of host names to environment names
const envByHost = {
  localhost: "local",
  "playbook.preview.im": "production",
} as Record<string, string>;

export const getEnv = (): string => {
  const env = envByHost[window.location.hostname];

  return env || "local";
};

// Map of environment names to Directus instances
const directusBaseUrlByEnv = {
  local: "https://playbook-directus.preview.im/", // 'https://dev-synnex-sc-centralus-directus.azurewebsites.net/',
  production: "https://playbook-directus.preview.im/",
} as Record<string, string>;

const assetQuality = {
  local: "30",
  production: "30",
} as Record<string, string>;

export const getDirectusBaseUrl = (): string => {
  const env = getEnv();
  return directusBaseUrlByEnv[env];
};

export const getAssetQuality = (): string => {
  const env = getEnv();
  return assetQuality[env];
};
