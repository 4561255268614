import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../api/user.types'

export interface IUserState {
  user: IUser | null;
}

const initialState: IUserState = {
  user: null
}

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
    resetUser: (state) => {
      state.user = null
    }
  }
})

export default userSlice.reducer

export const { logout, setUser, resetUser } = userSlice.actions
