import React from "react";
import "./PostList.scss";
import { PostListType } from "./PostList.props";
import { PostCard } from "../PostCard/PostCard";

export const PostList = (props: PostListType) => {
  const postListClass: string[] = ["post-list"];
  if (props.postData.length === 1) {
    postListClass.push("post-list-one");
  } else if (props.postData.length === 2) {
    postListClass.push("post-list-two");
  } else if (props.template === "three") {
    if (props.postData.length >= 3) {
      postListClass.push("post-list-three");
    }
  } else if (props.template === "two") {
    if (props.postData.length === 3) {
      postListClass.push("post-list-three");
    } else {
      postListClass.push("post-list-four");
    }
  } else {
    if (props.postData.length === 3) {
      postListClass.push("post-list-three");
    } else if (props.postData.length % 5 === 0) {
      postListClass.push("post-list-five");
    } else if (props.postData.length % 4 === 0) {
      postListClass.push("post-list-four");
    } else if (props.postData.length % 3 === 0) {
      postListClass.push("post-list-three");
    } else {
      postListClass.push("post-list-five");
    }
  }
  const posts = props.postData.map((post) => (
    <li key={"main_" + post.permalink} className="flex">
      <PostCard {...post} />
    </li>
  ));

  return (
    <>
      {posts.length ? (
        <ul className={postListClass.join(" ")}>{posts}</ul>
      ) : (
        <></>
      )}
    </>
  );
};
