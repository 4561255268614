import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import "./PostCardSimple.scss";
import { PostType } from "../PostCard/PostCard.props";
import TextTruncate from "react-text-truncate";
import { removeTags } from "../../utils/mainUtils";
import { useTrackLinkOnClick } from "../../hooks/useTrackLinks";
import defaultCard from "../../assets/images/logos/card_logo_default.png";
import lockIcon from "../../assets/images/icons/icon_lock_white.svg";
import { Link } from "react-router-dom";
import { getAssetQuality } from "../../utils/envUtils";

export const PostCardSimple = (props: PostType) => {
  const token = localStorage.getItem("p-token");
  const [data, setData] = useState<PostType>(props);

  useEffect(() => {
    setData(props);
  }, [props]);

  const getTitle = () => {
    return removeTags(data.title) as string;
  };
  const handleTrackLink = useTrackLinkOnClick("link card");
  return (
    <div className={`post-simple ${!data.isPublic && !token ? "locked" : ""}`}>
      <Tooltip anchorSelect=".post-simple.locked" className="font-display">
        Please login to see this page.
      </Tooltip>
      {!data.isPublic && !token && (
        <div className="post-locked-icon">
          <img
            className="w-12 h-12"
            src={lockIcon}
            alt="Locked"
            loading="lazy"
          />
        </div>
      )}
      <Link
        className="link-wrap"
        to={data.isPublic || token ? data.permalink : ""}
        onClick={handleTrackLink}
      >
        <div className="post-img">
          <img
            src={
              data.thumbnail
                ? `${
                    data.thumbnail
                  }?height=140&fit=contain&quality=${getAssetQuality()}&format=webp`
                : defaultCard
            }
            alt="Post Image"
            loading="lazy"
          />
        </div>
        <div className="post-content">
          <TextTruncate
            line={3}
            element="h2"
            truncateText="…"
            text={getTitle()}
          />
        </div>
      </Link>
    </div>
  );
};
