export enum PaginationDirectionType {
  Direct, // eslint-disable-line
  Previous, // eslint-disable-line
  Next, // eslint-disable-line
  Relative, // eslint-disable-line
}

export interface PostPaginationType {
  numberOfPages: number;
  startingIndex: number;
  handlePagination: (index: number) => void;
}
