const removeTags = (str: string) => {
  if (str === null || str === '') {
    return false
  } else {
    str = str.toString()
  }
  return str.replace(/(<([^>]+)>)/gi, '')
}

const getParamList = (name: string, queryParams: URLSearchParams): string[] => {
  const param = queryParams.get(name)

  return param ? param.split(',') : ([] as string[])
}

export { removeTags, getParamList }
