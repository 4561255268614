import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { ScrollUp } from "./components/ScrollUp/ScrollUp";
import { Toaster } from "react-hot-toast";
import CookieConsentBar from "./components/CookieConsentBar/CookieConsentBar";

import mixpanel from "mixpanel-browser";
import { getSessionId } from "./utils/storageUtils";
import { version } from "./utils/printVersion";
import { getBasePath } from "./utils/withBaseUrl";
import routes from "./routes";

const basename = getBasePath();
const toastOptions = {
  duration: 4000,
  success: {
    style: {
      background: "green",
    },
  },
  error: {
    style: {
      background: "red",
    },
  },
  className: "font-display text-lg text-white text-left",
};

mixpanel.init("fb9c2ecd79b13d5a846c121ed195c515", { debug: true });
mixpanel.register({
  sessionId: getSessionId(),
  version,
});

function App() {
  return (
    <div className="min-h-screen App">
      <CookieConsentBar />
      <Toaster toastOptions={toastOptions} />
      <BrowserRouter basename={basename}>
        <Routes>
          {routes.map(({ path, isPrivate, Component }, key) => (
            <Route
              path={path}
              key={key}
              element={
                isPrivate ? (
                  <PrivateRoute>
                    <Component />
                  </PrivateRoute>
                ) : (
                  <Component />
                )
              }
            />
          ))}
        </Routes>
        <ScrollUp />
      </BrowserRouter>
    </div>
  );
}

export default App;
