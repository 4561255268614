import React from 'react'
import './PrintButton.scss'

export const PrintButton = () => {
  const printHandler = () => {
    window.print()
  }

  return (
    <button
      type="button"
      className="bg-primary text-white rounded py-2 px-6 font-display"
      onClick={printHandler}
    >
      <div className="flex justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 mr-2"
          viewBox="0 0 24 24"
        >
          <path fill="#FFF" d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7l7-7Z" />
        </svg>

        <p className="ml-2 mb-0">Download</p>
      </div>
    </button>
  )
}
