import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setUser } from '../reducers/userReducer'
import { IUser } from './user.types'
import { getDirectusBaseUrl } from '../utils/envUtils'

export const userApi = createApi({
  reducerPath: 'userApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: getDirectusBaseUrl(),
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('p-token')}`)
      return headers
    }
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getMe: builder.mutation<IUser, { id: string | undefined }>({
      query ({ id }) {
        return {
          url: `users/${id}`,
          method: 'GET'
        }
      },
      transformResponse: (result: { data: any }) =>
        result.data,
      async onQueryStarted (args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (error) {}
      }
    })
  })
})

export const { useGetMeMutation } = userApi
