import React, { useEffect, useState } from "react";
import "./Hero.scss";
import { HeroType, HeroStyle, HeroThumbnailStyle } from "./Hero.props";
import dottedCircle from "../../assets/images/dotted-circle.png";
import classnames from "classnames";
import Moment from "react-moment";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getAssetQuality } from "../../utils/envUtils";

export const Hero = (props: HeroType) => {
  const [data, setData] = useState<HeroType>(props);
  const [bannerHeight, setBannerHeight] = useState<number>(0);

  useEffect(() => {
    setData(props);
  }, [props]);

  const heroClass = classnames(
    "hero ",
    { secondary: data.style === HeroStyle.Secondary },
    { tertiary: data.style === HeroStyle.Tertiary }
  );
  const [thumbnailType] = useState<string>(
    data.thumbnailType ? data.thumbnailType : "image/png"
  );
  const thumbnailClass = classnames(
    "hero-inner-right w-full lg:w-1/2 lg:pt-10",
    { circle: data.thumbnailStyle === HeroThumbnailStyle.Circle },
    thumbnailType.includes("video") ? "hero-thumbnail-video-wrapper" : ""
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767.98);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 767.98);
    setTimeout(() => {
      const contentHeight =
        document.querySelector(".hero-inner-left")?.scrollHeight;
      setBannerHeight((contentHeight as number) + 50);
    }, 500);
  });

  const getThumbnailBanner = () => {
    let bannerUrl = "";
    if (data.style === HeroStyle.Tertiary && data.thumbnail) {
      bannerUrl = `${data.thumbnail}?quality=${
        data.thumbnailQuality || getAssetQuality()
      }`;
    }
    return bannerUrl;
  };

  const thumbnail = data.thumbnail
    ? `${data.thumbnail}?quality=${data.thumbnailQuality || getAssetQuality()}`
    : undefined;

  return (
    <>
      <div
        className={heroClass}
        style={{
          height:
            data.style === HeroStyle.Tertiary && bannerHeight
              ? `${bannerHeight}px`
              : undefined,
          maxHeight:
            data.style === HeroStyle.Tertiary && bannerHeight
              ? "1000px"
              : undefined,
        }}
      >
        {data.style === HeroStyle.Tertiary && getThumbnailBanner() && (
          <img src={getThumbnailBanner()} />
        )}
        <HelmetProvider>
          <Helmet>
            <title>{`TD SYNNEX - ${data.title}`}</title>
            <meta name="description" content={data?.shortDescription} />
            <meta property="og:title" content={`TD SYNNEX - ${data.title}`} />
            <meta property="og:description" content={data?.shortDescription} />
          </Helmet>
        </HelmetProvider>
        <div
          className={data.style === HeroStyle.Tertiary ? "post-wrapper" : ""}
        >
          <div className="gap-5 px-3 hero-inner lg:gap-10">
            <div
              className="w-full hero-inner-left lg:w-1/2 lg:pt-10"
              style={{
                color:
                  data.style === HeroStyle.Tertiary && !getThumbnailBanner()
                    ? "black"
                    : undefined,
              }}
            >
              <div className="hero-divider"></div>
              {data.logo !== undefined &&
                data.logo?.length > 0 &&
                data.style === HeroStyle.Tertiary && (
                  <img className="hero-logo" src={data.logo} loading="lazy" />
                )}
              <h1>{data.title}</h1>
              {data.style === HeroStyle.Tertiary &&
                !isMobile &&
                data.isLandingPage && (
                  <div className="subTitle">
                    PLAYBOOK |{" "}
                    <span>
                      <Moment date={data.updatedDate} format={"MMMM YYYY"} />
                    </span>
                  </div>
                )}
              <div
                className="space-y-2 hero-content"
                dangerouslySetInnerHTML={{ __html: data.content }}
              ></div>
            </div>
            <div className={thumbnailClass}>
              <div className="pt-10 hero-thumbnail-wrap lg:p-0">
                {!thumbnailType.includes("video") && thumbnail && (
                  <img
                    className="hero-thumbnail--image"
                    src={thumbnail}
                    alt={data.title}
                    loading="lazy"
                  />
                )}
                {thumbnailType.includes("video") && thumbnail && (
                  <div
                    className={`hero-thumbnail hero-thumbnail-video`}
                    style={{
                      backgroundImage: `url(${thumbnail})`,
                    }}
                  >
                    <video autoPlay playsInline muted loop>
                      <source src={thumbnail} type={thumbnailType} />
                    </video>
                  </div>
                )}
                <img
                  className="hero-thumbnail-dashed"
                  src={dottedCircle}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.style === HeroStyle.Tertiary && isMobile && (
        <div className="subTitle subTitle-mobile">
          PLAYBOOK |{" "}
          <span>
            <Moment date={data.updatedDate} format={"MMMM YYYY"} />
          </span>
        </div>
      )}
    </>
  );
};
