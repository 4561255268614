import React, { useCallback } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { usePageQuery } from "../../api/api";
import { useParams } from "react-router";
import { TemplateOne } from "./TemplateOne/TemplateOne";
import { TemplateTwo } from "./TemplateTwo/TemplateTwo";
import { TemplateThree } from "./TemplateThree/TemplateThree";
import { TemplateFour } from "./TemplateFour/TemplateFour";
import { useAppSelector } from "../../store";

const pageByTemplateType = {
  template_1: <TemplateOne />,
  template_2: <TemplateTwo />,
  template_3: <TemplateThree />,
  template_4: <TemplateFour />,
} as any;

export const Page = () => {
  const { pageId } = useParams();
  const id = pageId ? parseInt(pageId ?? "", 10) : "";
  const { currentData, isLoading } = usePageQuery(id);
  const user = useAppSelector((state) => state.user.user);

  const templateDetails = pageByTemplateType[currentData?.templateType ?? ""];

  const renderTemplate = useCallback(() => {
    if (currentData) {
      if (!currentData.id) {
        return <Navigate to="/not-found" />;
      }
      if (currentData.isPublic) {
        return (
          <>
            {!isLoading && (
              <div className="templateUI max-w-full w-full mx-auto">
                {templateDetails}
              </div>
            )}
          </>
        );
      } else {
        let template = <></>;
        if (localStorage.getItem("p-token")) {
          template = (
            <>
              {!isLoading && (
                <div className="templateUI max-w-full w-full mx-auto">
                  {templateDetails}
                </div>
              )}
            </>
          );
          // if (user && currentData.tenants.includes(user.tenant)) {
          //   console.log('In Else Logged in Tenant Matches')
          // } else {
          // console.log('In Else Logged in Tenant Does not Matches')
          // template = <Navigate to="/unauthorized" replace />
          // }
        } else {
          toast.remove("login-err-toast");
          toast.error("Please login to continue!", { id: "login-err-toast" });
          localStorage.setItem("p-last-path", window.location.pathname);
          template = <Navigate to="/login" replace />;
        }
        return template;
      }
    }
  }, [currentData, user]);

  return <>{renderTemplate()}</>;
};
