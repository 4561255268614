import React from "react";
import logo from "../../assets/images/logos/logo.svg";

export const UnauthenticatedLayout = ({ children, title }: any) => {
  return (
    <div className="relative w-full h-screen flex flex-col top-0 items-center">
      <div className="w-full h-52 bg-primary flex justify-center items-center">
        <img src={logo} alt="Logo" loading="lazy"></img>
      </div>

      <div className="absolute w-full px-4 top-40">
        <div className="md:w-[540px] mx-auto pb-10">
          <div className="bg-white shadow-lg p-5 md:p-10 top-80 text-left rounded">
            <span className="text-black font-display block text-center text-3xl mb-12">
              {title}
            </span>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
