import React from 'react'

import { useParams } from 'react-router'
import { usePageQuery } from '../../../api/api'
import { ArticleList } from '../../../components/ArticleList/ArticleList'
import { Hero } from '../../../components/Hero/Hero'
import { HeroStyle } from '../../../components/Hero/Hero.props'
import { PostListSimple } from '../../../components/PostListSimple/PostListSimple'
import {
  extractArticleData,
  extractHeroData,
  extractPageLinkData
} from '../../../utils/pageDataUtils'
import defaultCard from '../../../assets/images/logos/card_logo_default.png'

export interface TemplateOneProps {
  pageIdOverride?: number;
}

export const TemplateOne = ({ pageIdOverride }: TemplateOneProps) => {
  const { pageId } = useParams()
  const isLandingPage = pageIdOverride !== undefined
  const id = pageIdOverride || parseInt(pageId ?? '', 10)
  const { currentData, isLoading, isError } = usePageQuery(id)

  if (isLoading === true || isError === true || currentData === undefined) {
    return <></>
  }
  const postSimpleData = extractPageLinkData(currentData.links || [])
  postSimpleData.forEach((element) => {
    if (element.thumbnail === defaultCard) {
      element.thumbnail = ''
    }
  })
  const heroData = extractHeroData(currentData)
  heroData.style = HeroStyle.Tertiary

  const articleListData = extractArticleData(currentData)
  return (
    <div className="templateOne">
      <Hero {...heroData} template="one" isLandingPage={isLandingPage} />
      <PostListSimple
        postData={postSimpleData}
        listTitle={currentData?.listTitle ?? ''}
      />
      <div className="post-wrapper">
        <ArticleList articleData={articleListData} />
      </div>
    </div>
  )
}
