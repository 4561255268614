const publicUrl = process.env.PUBLIC_URL
export const getBasePath = (): string => {
  if (publicUrl === undefined) {
    return ''
  }

  try {
    const urlObj = new URL(publicUrl)

    return urlObj.pathname
  } catch (error) {
    console.warn(
      'process.env.PUBLIC_URL is not a valid url, using it as base path.',
      publicUrl,
      error
    )
    return publicUrl
  }
}

export const withBaseUrl = (url: string): string => {
  const basename = getBasePath()
  const basenameWithLeadingSlash = basename.startsWith('/')
    ? basename
    : `/${basename}`
  const urlWithLeadingSlash = url.startsWith('/') ? url : `/${url}`

  return basename ? `${basenameWithLeadingSlash}${urlWithLeadingSlash}` : url
}
