import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import Button from '../../components/Form/Button'
import OTPInput from '../../components/Form/OTPInput'
import LinkButton from '../../components/Form/LinkButton'
import { useResendOtpMutation, useVerifyOtpMutation } from '../../api/auth'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout/UnauthenticatedLayout'
import mixpanel from 'mixpanel-browser'

export const OTPVerificationPage = () => {
  const [otp, setOtp] = useState('')
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(0)
  const onChange = (value: string) => setOtp(value)

  const [resendOtp, { isLoading, isError, error, isSuccess }] =
    useResendOtpMutation()

  const [
    verifyOtp,
    {
      isLoading: verifyIsLoading,
      isError: verifyIsError,
      error: verifyError,
      isSuccess: verifyIsSuccess
    }
  ] = useVerifyOtpMutation()

  const { state } = useLocation()
  const navigate = useNavigate()

  const submitForm = async () => {
    if (!otp) {
      toast.error('Please enter OTP to continue!')
      return
    }

    await verifyOtp({
      user: localStorage.getItem('p-user'),
      otp: Number(otp)
    })
  }

  const submitResendOtp = () => {
    setMinutes(1)
    setSeconds(0)
    resendOtp({ email: state?.email })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [minutes, seconds])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Resend OTP success')
      mixpanel.track('Resend OTP success', {}, { transport: 'sendBeacon' })
    }

    if (isError) {
      let message = ''
      if ((error as any).data?.errors) {
        (error as any).data.errors.forEach((errorDetails: any) => {
          toast.error(errorDetails?.message ?? errorDetails?.error ?? '')
          message = errorDetails?.message ?? errorDetails?.error ?? ''
        })
      } else if ((error as any).data?.error) {
        toast.error((error as any).data.error)
        message = (error as any).data.error
      } else if ((error as any).data?.message) {
        toast.error((error as any).data.message)
        message = (error as any).data.message
      } else {
        toast.error(
          'There might be some issue with the server. please try again!'
        )
        message =
          'There might be some issue with the server. please try again!'
      }
      mixpanel.track(
        'Resend OTP failed',
        { message },
        {
          transport: 'sendBeacon'
        }
      )
      setOtp('')
    }
  }, [isLoading])

  useEffect(() => {
    if (verifyIsSuccess) {
      toast.success('OTP verified successfully')
      toast.success('You are successfully logged in.')
      mixpanel.track(
        'OTP verification success',
        {},
        { transport: 'sendBeacon' }
      )
      mixpanel.track('login success', {}, { transport: 'sendBeacon' })
      navigate('/')
    }

    if (verifyIsError) {
      let message = ''
      if ((verifyError as any).data?.errors) {
        (verifyError as any).data.errors.forEach((errorDetails: any) => {
          toast.error(errorDetails?.message ?? errorDetails?.error ?? '')
          message = errorDetails?.message ?? errorDetails?.error ?? ''
        })
      } else if ((verifyError as any).data?.error) {
        toast.error((verifyError as any).data.error)
        message = (verifyError as any).data.error
      } else if ((verifyError as any).data?.message) {
        toast.error((verifyError as any).data.message)
        message = (verifyError as any).data.message
      } else {
        toast.error(
          'There might be some issue with the server. please try again!'
        )
        message =
          'There might be some issue with the server. please try again!'
      }
      mixpanel.track(
        'OTP verification failed',
        { message },
        {
          transport: 'sendBeacon'
        }
      )
    }
  }, [verifyIsLoading])

  return (
    <UnauthenticatedLayout title="OTP Verification">
      <form>
        <p className="text-gray text-center text-sm font-display mb-10">
          Your OTP has been sent to <strong>{state?.email}</strong>
        </p>

        <OTPInput value={otp} onChange={onChange} />

        <div className="flex justify-center items-center my-4">
          {seconds > 0 || minutes > 0
            ? (
            <p className="m-0 font-display mr-4">
              Time Remaining:{' '}
              <strong>
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </strong>
            </p>
              )
            : (
            <p className="m-0 font-display mr-4">Did not recieved OTP? </p>
              )}
          <LinkButton
            label="Resend OTP"
            disabled={seconds > 0 || minutes > 0}
            onClick={submitResendOtp}
            loading={isLoading}
          />
        </div>

        <div className="mt-5">
          <Button
            label="Verify"
            type="button"
            onClick={submitForm}
            loading={verifyIsLoading}
          />
        </div>
      </form>
    </UnauthenticatedLayout>
  )
}
