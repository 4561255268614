import React, { Suspense, useEffect, useState } from "react";
import { PageHeader } from "../PageHeader/PageHeader";
import { VerifyEmailBar } from "../VerifyEmailBar/VerifyEmailBar";
import { userApi } from "../../api/user";
import { store, useAppSelector } from "../../store";
import { FooterLinks } from "../FooterLinks/FooterLinks";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

export const PrivateRoute = ({ children }: any) => {
  const [token, setToken] = useState(localStorage.getItem("p-token"));
  const user = useAppSelector((state) => state.user.user);
  const isRootPath =
    window.location.pathname === "/" || window.location.pathname === "";

  useEffect(() => {
    if (token) {
      store.dispatch(
        userApi.endpoints.getMe.initiate({
          // @ts-ignore
          id: localStorage.getItem("p-user"),
        })
      );
    }
  }, [token]);

  window.addEventListener("storage", () => {
    setToken(localStorage.getItem("p-token"));
  });

  return (
    <>
      {token && user && !user.email_verified && (
        <VerifyEmailBar email={user.email} />
      )}
      <PageHeader isLoggedIn={token} user={user} />
      {!isRootPath && (
        <>
          <Breadcrumbs />
        </>
      )}
      <Suspense fallback={<> Loading ... </>}>{children}</Suspense>
      <div className="mt-auto text-white bg-primary">
        <FooterLinks />
      </div>
    </>
  );
};
