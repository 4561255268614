import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  menu: any | null;
}

const initialState: IUserState = {
  menu: null,
};

export const menuSlice = createSlice({
  initialState,
  name: "menuSlice",
  reducers: {
    setMenu: (state, action: PayloadAction<any>) => {
      state.menu = action.payload;
    },
    resetMenu: (state) => {
      state.menu = null;
    },
  },
});

export default menuSlice.reducer;

export const { setMenu, resetMenu } = menuSlice.actions;
