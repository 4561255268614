import React, { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Popup from "reactjs-popup";

import "./PageHeader.scss";
import mainLogo from "../../assets/images/logos/logo_main.png";
import { SearchBarHeader } from "../SearchBarHeader/SearchBarHeader";
import { useTrackLinkOnClick } from "../../hooks/useTrackLinks";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Form/Button";
import { resetUser } from "../../reducers/userReducer";
import { useDispatch } from "react-redux";
import { useFetchMenuMutation } from "../../api/menu";
import ClickAwayListener from "react-click-away-listener";
import classNames from "classnames";
interface PortalProps {
  children: ReactNode;
}

function Portal({ children }: PortalProps): React.ReactPortal | null {
  return ReactDOM.createPortal(
    children,
    document.getElementById("root") || document.body
  );
}

export const PageHeader = ({ user, isLoggedIn }: any) => {
  const [menus, setMenus] = useState([]);
  const [openMenu, setOpenMenu] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fetchMenu, { isLoading, isError, error, isSuccess, data }] =
    useFetchMenuMutation();

  const getMenu = async () => {
    let data: any = await fetchMenu({});
    setMenus(data?.data ?? []);
  };

  const handleTrackLink = useTrackLinkOnClick("header");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("p-token");
    localStorage.removeItem("p-user");
    localStorage.removeItem("p-last-path");
    localStorage.removeItem("seach__query");
    localStorage.removeItem("seach__filter");
    window.dispatchEvent(new Event("storage"));
    dispatch(resetUser());
    navigate("/");
    window.location.reload();
  };

  const login = () => {
    localStorage.setItem("p-last-path", window.location.pathname);
    navigate("/login");
  };

  useEffect(() => {
    getMenu();
  }, []);

  return (
    <>
      {openDrawer && (
        <Portal>
          <div
            className={classNames(
              "fixed top-0 w-screen h-screen md:hidden bg-neutral-800/40 backdrop-blur-[2px] flex items-center transition-all duration-300",
              openDrawer
                ? "translate-x-0 opacity-1"
                : "-translate-x-full opacity-50"
            )}
          >
            <div className="relative flex flex-col h-full p-6 shadow-lg w-72 shadow-primary bg-primary/95 backdrop-blur-sm">
              <button
                onClick={() => setOpenDrawer(false)}
                className="absolute top-0 right-0 p-4 text-3xl text-white rounded-full h-fit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                  />
                </svg>
              </button>
              <div className="mt-10">
                <SearchBarHeader />
              </div>

              <div className="flex flex-col gap-2 mt-7">
                {menus.map((menu: any, index: number) => {
                  return (
                    <div
                      key={`menu-${index}`}
                      className="flex flex-col text-white"
                    >
                      <Link
                        to={menu.link}
                        onClick={() => setOpenDrawer(false)}
                        className="lg:px-2 text-start mt-2 !font-sans"
                      >
                        {menu.label}
                      </Link>
                      {menu?.sub_menu.map((subMenu: any, index: number) => {
                        return (
                          <Link
                            key={`submenu-${index}`}
                            to={subMenu?.related_menu_id?.link}
                            onClick={() => setOpenDrawer(false)}
                            className="!font-sans ml-3 mt-2 text-start"
                          >
                            {subMenu?.related_menu_id?.label}
                          </Link>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Portal>
      )}
      <div
        id="PageHeader"
        className="page-header min-h-[66px] sm:min-h-[86px] md:min-h-[110px]"
      >
        <div className="pl-4 pr-2 md:px-6 lg:px-10 page-header-inner">
          <button type="button" onClick={() => setOpenDrawer(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              className="mr-3 text-white md:hidden "
            >
              <path
                fill="currentColor"
                d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"
              />
            </svg>
          </button>
          <div className="logo-wrapper max-w-[176px] lg:max-w-[216px]">
            <Link to="/" onClick={handleTrackLink}>
              <img
                className="logo-image"
                src={mainLogo}
                alt="TD Synnex Logo"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="hidden search-wrapper md:block">
            <SearchBarHeader />
          </div>
          <div className="hidden gap-5 mr-2 md:mr-3 lg:mr-10 text-shimmer md:flex">
            {menus.map((menu: any, index: number) => {
              if (menu?.sub_menu?.length) {
                return (
                  <div key={`sub-${index}`} className="relative">
                    <Popup
                      trigger={
                        <button
                          type="button"
                          className="lg:px-2 px-1 !font-sans"
                        >
                          {menu.label}
                        </button>
                      }
                      position={["bottom center", "top center"]}
                      closeOnDocumentClick
                    >
                      <div className="flex flex-col divide-y divide-slate-200">
                        {menu?.sub_menu.map((subMenu: any, index: number) => (
                          <Link
                            key={`subm-${index}`}
                            to={subMenu?.related_menu_id?.link}
                            className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[400px] hover:text-white hover:bg-primary outline-none p-3 text-left"
                          >
                            {subMenu?.related_menu_id?.label}
                          </Link>
                        ))}
                      </div>
                    </Popup>
                  </div>
                );
              }
              return (
                <Link
                  key={`sub-${index}`}
                  to={menu.link}
                  className="px-1 lg:px-2 !font-sans"
                >
                  {menu.label}
                </Link>
              );
            })}
          </div>
          <div className="ml-auto md:ml-3">
            {!isLoggedIn && (
              <Button
                label="Login"
                invertColors
                type="button"
                onClick={login}
              />
            )}
            {isLoggedIn && (
              <Button
                label="Logout"
                invertColors
                type="button"
                onClick={logout}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
