import React from 'react'
import { deleteCookie, hasCookie, setCookie } from 'cookies-next'

const CookieConsentBar = () => {
  const [showConsent, setShowConsent] = React.useState(false)

  React.useEffect(() => {
    setShowConsent(!hasCookie('localConsent'))
  }, [])

  window.addEventListener('storage', () => {
    const status = localStorage.getItem('localConsent')
    if (status === 'true') {
      setCookie('localConsent', status, {})
      setShowConsent(false)
    } else {
      deleteCookie('localConsent')
    }
  })

  const acceptCookieConsent = () => {
    localStorage.setItem('localConsent', 'true')
    window.dispatchEvent(new Event('storage'))
    setCookie('localConsent', 'true', {})
  }

  const declineCookieConsent = () => {
    localStorage.setItem('localConsent', 'false')
    window.dispatchEvent(new Event('storage'))
    setShowConsent(false)
  }

  return (
    <>
      {showConsent && (
        <div className="fixed inset-0 bg-gray bg-opacity-80 z-10">
          <div className="fixed bottom-0 left-0 right-0 flex items-center justify-between px-4 py-4 bg-primary">
            <strong
              className="text-xl cursor-pointer absolute right-0 -top-2 p-1 text-white"
              onClick={() => declineCookieConsent()}
            >
              &times;
            </strong>
            <span className="text-white text-base font-display mr-16">
              This website uses cookies to improve user experience. By using our
              website you consent to all cookies in accordance with our Cookie
              Policy.
            </span>
            <button
              className="bg-success-500 py-2 px-8 mr-5 font-display rounded text-white"
              onClick={() => acceptCookieConsent()}
            >
              Accept
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieConsentBar
