import React, { MouseEventHandler, forwardRef } from 'react'
import { LoadingIcon } from '../../assets/images/icons/icon_loading'

interface ButtonProps {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
}

const Button = (
  { label, onClick, disabled, loading }: ButtonProps,
  ref: any
) => {
  return (
    <button
      ref={ref}
      className="text-primary background-transparent disabled:text-gray disabled:cursor-not-allowed font-display font-bold text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
      disabled={disabled}
      type='button'
      onClick={onClick}
    >
      {loading ? <LoadingIcon /> : label}
    </button>
  )
}

export default forwardRef(Button)
