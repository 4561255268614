import React, { useState, useEffect } from 'react'
import './ScrollUp.scss'
import classNames from 'classnames'

export const ScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false)
  const classes = classNames('scroll-up', { visible: isVisible })

  const scrollUpHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    setIsVisible(winScroll > window.screen.height)
  }

  return (
    <div className={classes} onClick={() => scrollUpHandler()}>
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5013 40.0834C17.9296 40.0834 15.383 39.5769 13.0071 38.5927C10.6311 37.6086 8.47228 36.1661 6.65379 34.3476C2.98121 30.675 0.917969 25.6939 0.917969 20.5001C0.917969 15.3063 2.98121 10.3252 6.65379 6.65257C10.3264 2.97999 15.3075 0.916748 20.5013 0.916748C23.073 0.916748 25.6196 1.42329 27.9955 2.40744C30.3715 3.3916 32.5303 4.83409 34.3488 6.65257C36.1673 8.47105 37.6098 10.6299 38.5939 13.0059C39.5781 15.3818 40.0846 17.9284 40.0846 20.5001C40.0846 25.6939 38.0214 30.675 34.3488 34.3476C30.6762 38.0202 25.6951 40.0834 20.5013 40.0834ZM20.5013 10.7084L10.7096 20.5001H16.5846V28.3334H24.418V20.5001H30.293L20.5013 10.7084Z"
          fill="#1D3152"
        />
      </svg>
    </div>
  )
}
