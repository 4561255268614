export enum HeroStyle {
  Primary, // eslint-disable-line
  Secondary, // eslint-disable-line
  Tertiary, // eslint-disable-line
}

export enum HeroThumbnailStyle {
  Regular, // eslint-disable-line
  Circle, // eslint-disable-line
}

export interface HeroType {
  title: string;
  shortDescription?: string;
  subTitle?: string;
  postDate?: string;
  logo?: string;
  content: string;
  style: HeroStyle;
  thumbnail?: string;
  thumbnailType?: string;
  thumbnailQuality?: string;
  thumbnailStyle: HeroThumbnailStyle;
  updatedDate: string;
  isLandingPage?: boolean;
  template?: string;
}
