import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import successIcon from "../../assets/images/icons/icon_sucess.svg";
import errorIcon from "../../assets/images/icons/icon_error.svg";
import { UnauthenticatedLayout } from "../UnauthenticatedLayout/UnauthenticatedLayout";
import Button from "../../components/Form/Button";

export const EmailVerificationPage = () => {
  const [isSuccess, setIsSuccess] = useState<any>("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const status = searchParams.get("status");
    setIsSuccess(status);
  }, [searchParams]);

  const redirectToMainPage = () => {
    window.location.href = window.location.origin;
  };

  return (
    <UnauthenticatedLayout title="Email Verification">
      {isSuccess === "true" ? (
        <div className="flex flex-col justify-center items-center text-center">
          <img
            className="w-12 h-12 mb-4"
            src={successIcon}
            alt="Success"
            loading="lazy"
          />
          <p className="m-0 font-display text-lg mb-10">
            Your email has been verified successfully.
          </p>
          <Button
            type="button"
            label="Explore Now"
            onClick={redirectToMainPage}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center text-center">
          <img
            className="w-12 h-12 mb-4"
            src={errorIcon}
            alt="Error"
            loading="lazy"
          />
          <p className="m-0 font-display text-lg mb-10">
            Your verification request is either expired or there might be some
            issue with our server. Please try again later.
          </p>
          <Button
            type="button"
            label="Explore Now"
            onClick={redirectToMainPage}
          />
        </div>
      )}
    </UnauthenticatedLayout>
  );
};
