import React, { useCallback } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { TemplateOne } from "../Page/TemplateOne/TemplateOne";
import { useLandingPageQuery } from "../../api/api";

export const LandingPage = () => {
  const { currentData, isLoading } = useLandingPageQuery();

  const renderTemplate = useCallback(() => {
    let template = <></>;
    if (currentData) {
      if (currentData.isPublic) {
        template = (
          <div className="templateUI max-w-full w-full mx-auto">
            {!isLoading && (
              <>
                <TemplateOne pageIdOverride={currentData.id} />
              </>
            )}
          </div>
        );
      } else {
        if (localStorage.getItem("p-token")) {
          template = <Navigate to="/unauthorized" replace />;
        } else {
          toast.remove("login-err-toast");
          toast.error("Please login to continue!", { id: "login-err-toast" });
          localStorage.setItem("p-last-path", window.location.pathname);
          template = <Navigate to="/login" replace />;
        }
      }

      return template;
    }
  }, [currentData]);

  return <>{renderTemplate()}</>;
};
