import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchQuery } from "../../api/api";
import { FilterBar } from "../../components/FilterBar/FilterBar";
import { PostListSearch } from "../../components/PostListSearch/PostListSearch";
import "./SearchPage.scss";
import {
  extractPageLinkData,
  extractUniqueMetadata,
} from "../../utils/pageDataUtils";
import { Metadata, Tag } from "../../api/page.types";
import { decode } from "js-base64";

const getMetadataFromParams = (): Metadata => {
  const queryParams = new URLSearchParams(window.location.search);
  const filterData: Metadata = {};

  queryParams.forEach((value: string, key: string) => {
    let tagGroup = filterData[key];
    if (!tagGroup) {
      tagGroup = filterData[key] = [];
    }

    const tags = value
      ? value.split(",").map((tag) => ({ displayName: tag }))
      : ([] as Tag[]);

    tags.forEach((tag) => tagGroup.push(tag));
  });

  return filterData;
};

export const SearchPage = () => {
  const [numberPerPage] = useState(15);
  const { searchValue } = useParams();
  const searchData = searchValue === undefined ? "" : decode(searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const switchCurrentPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const filterData = getMetadataFromParams();

  const { currentData, isLoading } = useSearchQuery({
    search: searchData,
    metadata: filterData,
  });

  const currentMetadata = useMemo(
    () => extractUniqueMetadata(currentData),
    [currentData]
  );
  const postListData = useMemo(
    () => extractPageLinkData(currentData || []),
    [currentData]
  );
  const filterString = Object.entries(filterData)
    .flatMap(([groupName, tagList]) => tagList.map((tag) => tag.displayName))
    .join(", ");

  return (
    <div className="container pb-5 searchPage md:mx-11">
      <div className="search-breadcrumbs">
        {(currentPage - 1) * numberPerPage + 1}
        {" - "}
        {currentPage * numberPerPage}
        {" OF OVER "}
        {postListData.length}
        {" RESULTS "}
        {searchData.length || filterString.length ? 'FOR "' : ""}
        {searchData.length ? searchData : filterString}
        {searchData.length || filterString.length ? '"' : ""}
      </div>
      <div className="searchResults">
        <FilterBar fromMetadata={currentMetadata} isLoading={isLoading} />
        <PostListSearch
          postData={postListData}
          switchCurrentPage={switchCurrentPage}
        />
      </div>
    </div>
  );
};
