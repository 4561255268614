export const getSearchUrl = ({ search }: { search: string }): string => {
  const path = 'items/Page'
  const fieldNames = [
    '*',
    'tags.*',
    'tags.Tag_id.*',
    'tags.Tag_id.tagGroup.*',
    'Hero_Image.*',
    'Thumbnail.*',
    'Logo.*',
    'Background.*'
  ]
  const fields = `fields[]=${fieldNames.join(',')}&limit=-1`
  const searchQuery = `search=${encodeURIComponent(search)}`
  return search ? `${path}?${searchQuery}&${fields}` : `${path}?${fields}`
}
