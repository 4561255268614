import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  LoginRequest,
  LoginResponse,
  CommonResponse,
  CommonRequest,
  ResetPasswordRequest,
  VerifyOTPRequest,
  VerifyOTPResponse
} from './auth.types'
import { IUser } from './user.types'
import { getDirectusBaseUrl } from '../utils/envUtils'
import { userApi } from './user'

export const authApi = createApi({
  reducerPath: 'authApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: getDirectusBaseUrl()
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<IUser, IUser>({
      query (payload) {
        payload.role = '919f743d-e21f-4ec9-b823-112122836ed1' // admin role
        return {
          url: 'users', // Directus inbuild API to create a user
          method: 'POST',
          body: payload
        }
      }
    }),
    loginUser: builder.mutation<LoginResponse, LoginRequest>({
      query (payload) {
        return {
          url: 'login',
          method: 'POST',
          body: payload,
          credentials: 'include'
        }
      },
      async onQueryStarted (args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data?.token) {
            localStorage.setItem('p-token', data.token)
          }
          if (data?.user) {
            localStorage.setItem('p-user', data.user)
            if (localStorage.getItem('p-token')) {
              await dispatch(userApi.endpoints.getMe.initiate({ id: data.user }))
            }
          }
        } catch (error) {}
      }
    }),
    changePasswordRequest: builder.mutation<CommonResponse, CommonRequest>({
      query ({ email }) {
        return {
          url: `change_password?email=${encodeURIComponent(email)}`,
          method: 'GET'
        }
      }
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query (payload) {
        return {
          url: 'reset_password',
          method: 'POST',
          body: payload
        }
      }
    }),
    resendOtp: builder.mutation<CommonResponse, CommonRequest>({
      query ({ email }) {
        return {
          url: `resent_otp?email=${encodeURIComponent(email)}`,
          method: 'GET'
        }
      }
    }),
    verifyOtp: builder.mutation<VerifyOTPResponse, VerifyOTPRequest>({
      query (payload) {
        return {
          url: 'otp',
          method: 'POST',
          body: payload
        }
      },
      async onQueryStarted (args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data?.token) {
            localStorage.setItem('p-token', data.token)
            if (localStorage.getItem('p-user')) {
              // @ts-ignore
              await dispatch(userApi.endpoints.getMe.initiate({ id: localStorage.getItem('p-user') }))
            }
          }
        } catch (error) {}
      }
    }),
    sendVerifyEmail: builder.mutation<CommonResponse, CommonRequest>({
      query ({ email }) {
        return {
          url: `send_verification_email?email=${encodeURIComponent(email)}`,
          method: 'GET'
        }
      }
    })
  })
})

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useSendVerifyEmailMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useChangePasswordRequestMutation,
  useResendOtpMutation
} = authApi
