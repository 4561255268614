import React, { useRef, useState } from 'react'
import './CollapseBox.scss'
import toggleIcon from '../../assets/images/icons/icon_toggle.png'
import toggleIconOpened from '../../assets/images/icons/icon_toggle_opened.png'

import { CollapseBoxType } from './CollapseBox.props'
import * as DOMPurify from 'dompurify'
export const CollapseBox = (props: CollapseBoxType) => {
  const [isOpen, setIsOpen] = useState(false)

  const boxRef = useRef<HTMLDivElement>(null)
  const toggleCollapse = () => {
    setIsOpen(!isOpen)
    if (!boxRef.current) {
      return
    }
    if (boxRef.current.classList.contains('open')) {
      boxRef.current.classList.remove('open')
    } else {
      boxRef.current.classList.add('open')
    }
  }
  const transformedContent = DOMPurify.sanitize(props.content, {
    FORBID_ATTR: ['style'],
    FORBID_TAGS: ['mark'],
    ADD_TAGS: ['iframe'],
    ADD_ATTR: [
      'allow',
      'allowfullscreen',
      'frameborder',
      'scrolling',
      'target',
      'rel'
    ]
  })

  const icon = isOpen ? toggleIconOpened : toggleIcon

  return (
    <div ref={boxRef} className="collapse-box">
      <div className="collapse-box-inner">
        <div className="collapse-box-header">
          <button onClick={toggleCollapse}>
            <h2>{props.title}</h2>
            <img src={icon} alt="toggle icon" loading='lazy' />
          </button>
        </div>
        <div className="collapse-box-content">
          <div
            className="collapse-box-content-inner"
            dangerouslySetInnerHTML={{ __html: transformedContent }}
          ></div>
        </div>
      </div>
    </div>
  )
}
