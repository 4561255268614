import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import mixpanel from 'mixpanel-browser'
import { toast } from 'react-hot-toast'
import Input from '../../components/Form/Input'
import Button from '../../components/Form/Button'
import { useLoginUserMutation } from '../../api/auth'
import { IPResponse } from '../../api/auth.types'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout/UnauthenticatedLayout'
import { useAppSelector } from '../../store'

export const LoginPage = () => {
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm()

  const [loginUser, { isLoading, isError, error, isSuccess, data }] =
    useLoginUserMutation()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.user)

  const submitForm = async (values: any) => {
    if (localStorage.getItem('localConsent') === 'true') {
      // Reference: https://route360.dev/en/post/get-ip-react/
      const res = await fetch('https://ipapi.co/json')
        .then((response) => response.json())
        .then((res: IPResponse) => res)

      values.ip = res.ip
      values.user_agent = window.navigator.userAgent
      await loginUser(values)
    } else {
      localStorage.setItem('localConsent', 'false')
      window.dispatchEvent(new Event('storage'))
      toast.error('Please accept storing cookies to continue with login!')
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('p-token')
    if (token) navigate('/')
  }, [])

  useEffect(() => {
    if (user) {
      // @ts-ignore
      mixpanel.identify(user.id)
      mixpanel.people.set({
        $first_name: user.first_name,
        $last_name: user.last_name,
        $emil: user.email
      })
    }
  }, [user])

  useEffect(() => {
    if (isSuccess) {
      if (data?.message) {
        const email = getValues('email')
        navigate('/otp-verification', {
          state: {
            email
          }
        })
      } else {
        toast.success('You are successfully logged in.')
        mixpanel.track('login success', {}, { transport: 'sendBeacon' })
        const lastPath = localStorage.getItem('p-last-path')
        navigate(lastPath || '/')
      }
    }

    if (isError) {
      showErrors(error)
    }
  }, [isLoading])

  const showErrors = (error: any) => {
    let message = ''
    if (error.data?.errors) {
      error.data.errors.forEach((errorDetails: any) => {
        toast.error(errorDetails?.message ?? errorDetails?.error ?? '')
        message = errorDetails?.message ?? errorDetails?.error ?? ''
      })
    } else if (error.data?.error) {
      toast.error(error.data.error)
      message = error.data.error
    } else if (error.data?.message) {
      toast.error(error.data.message)
      message = error.data.message
    } else {
      toast.error(
        'There might be some issue with the server. please try again!'
      )
      message = 'There might be some issue with the server. please try again!'
    }
    mixpanel.track('login failed', { message }, { transport: 'sendBeacon' })
  }

  return (
    <UnauthenticatedLayout title="Login">
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          className="mb-4"
          type="email"
          label="Email *"
          placeholder="Enter your email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: 'Invalid email'
            }
          })}
          aria-invalid={errors.email ? 'true' : 'false'}
          error={errors.email}
        />

        <Input
          className="mb-6"
          type="password"
          label="Password *"
          placeholder="Enter your password"
          {...register('password', { required: 'Password is required' })}
          aria-invalid={errors.password ? 'true' : 'false'}
          error={errors.password}
        />

        <div className="flex justify-end">
          <Link
            className="inline-block align-baseline text-sm text-gray underline hover:no-underline font-display"
            to={'/forgot-password'}
          >
            Forgot Password?
          </Link>
        </div>

        <div className="mt-5">
          <Button label="Login" type="submit" loading={isLoading} />
        </div>

        <div className="mt-6 flex justify-center items-center">
          <p className="font-display m-0">Don&apos;t have an account? </p>

          <Link
            className="inline-block align-baseline ml-1 text-black underline hover:no-underline font-display"
            to="/signup"
          >
            Signup now
          </Link>
        </div>
        <div className="mt-6 flex justify-center items-center">
          <Link
            className="inline-block align-baseline ml-1 text-black underline hover:no-underline font-display"
            to="/"
          >
            Back to dashboard
          </Link>
        </div>
      </form>
    </UnauthenticatedLayout>
  )
}
