import React from "react";
import { Link } from "react-router-dom";
import { BreadcrumbProps } from "../../api/page.types";

export const ChevronRight = () => {
  return (
    <span className="text-gray">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        className="w-8 h-7"
      >
        <path
          fill="currentColor"
          d="m13.292 12l-4.6-4.6l.708-.708L14.708 12L9.4 17.308l-.708-.708z"
        />
      </svg>
    </span>
  );
};

export const MoreIcon = () => {
  return (
    <span className="text-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        className="w-6 h-6"
      >
        <path
          fill="currentColor"
          d="M16 12a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2Z"
        />
      </svg>
    </span>
  );
};

const BreadcrumbItem = ({
  breadcrumb,
  theme,
}: {
  breadcrumb: BreadcrumbProps | undefined;
  theme: string;
}) => {
  const setTheme = (value: string) => {
    if (value === "primary") {
      return "text-white bg-primary hover:bg-primaryLite";
    }
    return "bg-[#EEEEEE] text-primary hover:bg-[#F4F4F4]";
  };

  return (
    <li
      className="inline-flex items-center"
      key={breadcrumb?.id}
      title={`(#${breadcrumb?.slug}) ${breadcrumb?.label}`}
    >
      <Link
        to={`/${breadcrumb?.slug}`}
        className={`whitespace-nowrap overflow-hidden text-ellipsis max-w-[400px] px-6 py-1.5 rounded-full ${setTheme(
          theme
        )}`}
      >
        <span>{breadcrumb?.label}</span>
      </Link>
    </li>
  );
};

export default BreadcrumbItem;
