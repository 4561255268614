export enum PostCardStyle {
  Main, // eslint-disable-line
  Wide, // eslint-disable-line
}

export interface PostType {
  id: number;
  title: string;
  description: string;
  thumbnail: string;
  // TEMP: might not need this
  thumbnail_circle: string;
  //
  content: string;
  permalink: string;
  post_date: string;
  isPublic?: boolean;
  card_type?: PostCardStyle;
  previewLink?: boolean;
}
