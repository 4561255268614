import { Metadata } from '../api/page.types'
import { createEmptyMetadata } from './metadataUtils'
import { v4 as uuidv4 } from 'uuid'

const queryKey = 'seach__query'
export const setStoredQuery = (query: string) =>
  localStorage.setItem(queryKey, query)
export const getStoredQuery = (): string =>
  localStorage.getItem(queryKey) || ''

const filterKey = 'seach__filter'
export const setStoredFilter = (filter: Metadata) =>
  localStorage.setItem(filterKey, JSON.stringify(filter))
export const getStoredFilter = (): Metadata => {
  const rawData = localStorage.getItem(filterKey)
  if (!rawData) return createEmptyMetadata()

  try {
    const data = JSON.parse(rawData)
    return data
  } catch (jsonParseError) {
    console.log(`Problem parsing localStorage JSON data for ${filterKey}`)
    return createEmptyMetadata()
  }
}

const sessionIdKey = 'session__id'
export const getSessionId = (): string => {
  let sessionId = sessionStorage.getItem(sessionIdKey)
  if (!sessionId) {
    sessionId = uuidv4()
    sessionStorage.setItem(sessionIdKey, sessionId)
  }

  return sessionId
}
