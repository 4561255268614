import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import successIcon from "../../assets/images/icons/icon_sucess.svg";
import { useChangePasswordRequestMutation } from "../../api/auth";
import { UnauthenticatedLayout } from "../UnauthenticatedLayout/UnauthenticatedLayout";
import mixpanel from "mixpanel-browser";

export const ForgotPasswordPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [changePasswordRequest, { isLoading, isError, error, isSuccess }] =
    useChangePasswordRequestMutation();

  const [email, setEmail] = useState<string>("");

  const submitForm = async (values: any) => {
    setEmail(values.email);
    await changePasswordRequest(values);
  };

  useEffect(() => {
    if (isSuccess) {
      mixpanel.track(
        "Forgot password request success",
        {},
        { transport: "sendBeacon" }
      );
    }

    if (isError) {
      let message = "";
      if ((error as any).data?.errors) {
        (error as any).data.errors.forEach((errorDetails: any) => {
          toast.error(errorDetails?.message ?? errorDetails?.error ?? "");
          message = errorDetails?.message ?? errorDetails?.error ?? "";
        });
      } else if ((error as any).data?.error) {
        toast.error((error as any).data.error);
        message = (error as any).data.error;
      } else if ((error as any).data?.message) {
        toast.error((error as any).data.message);
        message = (error as any).data.message;
      } else {
        toast.error(
          "There might be some issue with the server. please try again!"
        );
        message =
          "There might be some issue with the server. please try again!";
      }
      mixpanel.track(
        "Forgot password request failed",
        { message },
        {
          transport: "sendBeacon",
        }
      );
    }
  }, [isLoading]);

  return (
    <UnauthenticatedLayout title="Forgot Password">
      {isSuccess ? (
        <div className="flex flex-col justify-center items-center text-center">
          <img
            className="w-12 h-12 mb-4"
            src={successIcon}
            alt="Success"
            loading="lazy"
          />
          <p className="m-0 font-display text-lg">
            Your change password request has been sent your email:{" "}
            <strong>{email}</strong>. Please check your email.
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit(submitForm)}>
          <Input
            className="mb-4"
            type="email"
            label="Email *"
            placeholder="Enter your email"
            {...register("email", { required: "Email is required" })}
            aria-invalid={errors.email ? "true" : "false"}
            error={errors.email}
          />

          <div className="mt-5">
            <Button label="Forgot Password" type="submit" loading={isLoading} />
          </div>

          <div className="mt-6 flex justify-center items-center">
            <p className="font-display m-0">Go back? </p>
            <Link
              className="inline-block align-baseline ml-1 text-black underline hover:no-underline font-display"
              to={"/login"}
            >
              Login
            </Link>
          </div>
        </form>
      )}
    </UnauthenticatedLayout>
  );
};
