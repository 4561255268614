import { ComponentExamples } from "./pages/ComponentExamples/ComponentExamples";
import { Page } from "./pages/Page/Page";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import { PreviewPage } from "./pages/PreviewPage/PreviewPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { SignupPage } from "./pages/SignupPage/SignupPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { OTPVerificationPage } from "./pages/OTPVerificationPage/OTPVerificationPage";
import { ChangePasswordPage } from "./pages/ChangePasswordPage/ChangePasswordPage";
import { EmailVerificationPage } from "./pages/EmailVerificationPage/EmailVerificationPage";
import { UnauthorizedPage } from "./pages/UnauthorizedPage/UnauthorizedPage";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";

export default [
  {
    path: "/login",
    breadcrumb: "Login",
    isPrivate: false,
    Component: LoginPage,
  },
  {
    path: "/signup",
    breadcrumb: "Signup",
    isPrivate: false,
    Component: SignupPage,
  },
  {
    path: "/otp-verification",
    breadcrumb: "OTP Verification",
    isPrivate: false,
    Component: OTPVerificationPage,
  },
  {
    path: "/forgot-password",
    breadcrumb: "Forgot Password",
    isPrivate: false,
    Component: ForgotPasswordPage,
  },
  {
    path: "/change-password",
    breadcrumb: "Change Password",
    isPrivate: false,
    Component: ChangePasswordPage,
  },
  {
    path: "/email-verification",
    breadcrumb: "Email Verification",
    isPrivate: false,
    Component: EmailVerificationPage,
  },
  {
    path: "/unauthorized",
    breadcrumb: "Unauthorized",
    isPrivate: false,
    Component: UnauthorizedPage,
  },
  {
    path: "/not-found",
    breadcrumb: "Not Found",
    isPrivate: false,
    Component: PageNotFound,
  },
  {
    path: "/examples",
    breadcrumb: "Examples",
    isPrivate: false,
    Component: ComponentExamples,
  },
  { path: "/", breadcrumb: "Home", isPrivate: true, Component: LandingPage },
  {
    path: "/:pageId",
    breadcrumb: "Page",
    isPrivate: true,
    Component: Page,
  },
  {
    path: "/search",
    breadcrumb: "Search",
    isPrivate: true,
    Component: SearchPage,
  },
  {
    path: "/search/:searchValue",
    breadcrumb: "Search Page",
    isPrivate: true,
    Component: SearchPage,
  },
  {
    path: "/preview",
    breadcrumb: "Preview",
    isPrivate: true,
    Component: PreviewPage,
  },
];
