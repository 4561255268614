import React from 'react'
import { useSelector } from 'react-redux'
import { selectFilter } from '../../selectors/searchSelectors'
import { FilterBarSectionType } from './FilterBarSection.props'
import './FilterBarSection.scss'

export const FilterBarSection = (props: FilterBarSectionType) => {
  const filters = useSelector(selectFilter)
  const options = props.options.map((option, i) => {
    const checked = !!filters[props.title]?.find(
      (tag) => tag.displayName === option.value
    )
    return (
      <label key={props.title + i}>
        <input
          type="checkbox"
          value={option.value}
          name={props.title}
          defaultChecked={checked}
        />
        {option.title}
      </label>
    )
  })
  return (
    <fieldset className="filter-bar-section">
      <h4>{props.title}</h4>
      {options}
    </fieldset>
  )
}
