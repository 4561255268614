import React, { useEffect, useRef, useState } from 'react'
import './PostSidebar.scss'
import { PostSidebarType } from './PostSidebar.props'
import { PostCardMini } from '../PostCardMini/PostCardMini'
import { PostType } from '../PostCard/PostCard.props'
import { PostPagination } from '../PostPagination/PostPagination'

export const PostSidebar = (props: PostSidebarType) => {
  const [numberPerPage] = useState(4)
  const getNumberOfChunkPages = () => {
    const postChunks = props.postData.filter((post, index) => {
      return index % numberPerPage === 0
    })
    return postChunks.length
  }
  const [numberOfChunkPages] = useState(getNumberOfChunkPages())

  const getPostChunks = (currentIndex: number) => {
    const currentChunks: PostType[] = []
    const postOffset = numberPerPage * currentIndex
    for (
      let postIndex = postOffset;
      postIndex < postOffset + numberPerPage;
      postIndex++
    ) {
      if (props.postData[postIndex]) {
        currentChunks.push(props.postData[postIndex])
      }
    }
    return currentChunks
  }
  const lastChunkIndex = useRef(0)
  const [chunkIndex, setChunkIndex] = useState(lastChunkIndex.current)
  const [currentChunks, setCurrentChunks] = useState(getPostChunks(chunkIndex))

  useEffect(() => {
    if (lastChunkIndex.current !== chunkIndex) {
      setCurrentChunks(getPostChunks(chunkIndex))
      lastChunkIndex.current = chunkIndex
    }
  }, [chunkIndex])

  const postChunks = currentChunks.map((post) => (
    <li key={'sidebar_' + post.permalink}>
      <PostCardMini {...post} />
    </li>
  ))

  return (
    <div className="post-sidebar">
      {props.sidebarTitle && <h3>{props.sidebarTitle}</h3>}
      <ul>{postChunks.length ? postChunks : <></>}</ul>
      {props.postData.length > numberPerPage && (
        <PostPagination
          numberOfPages={numberOfChunkPages}
          startingIndex={chunkIndex}
          handlePagination={setChunkIndex}
        />
      )}
    </div>
  )
}
