import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import Input from '../../components/Form/Input'
import Button from '../../components/Form/Button'
import Select from '../../components/Form/Select'
import { useRegisterUserMutation } from '../../api/auth'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout/UnauthenticatedLayout'
import mixpanel from 'mixpanel-browser'

export const SignupPage = () => {
  const {
    register,
    watch,
    control,
    formState: { errors },
    handleSubmit
  } = useForm()

  const navigate = useNavigate()

  const [registerUser, { isLoading, isError, isSuccess, error }] =
    useRegisterUserMutation()

  const [companyTypeOptions] = useState([
    {
      label: 'Reseller',
      value: 'reseller'
    },
    {
      label: 'VAR',
      value: 'var'
    },
    {
      label: 'MSP',
      value: 'msp'
    },
    {
      label: 'Vendor Partner',
      value: 'vendor_partner'
    },
    {
      label: 'End User',
      value: 'end_user'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ])

  const submitForm = async (values: any) => {
    const tempValues = { ...values }
    tempValues.company_type = values.company_type.value
    await registerUser(tempValues)
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('User registered successfully')
      mixpanel.track('signup success', {}, { transport: 'sendBeacon' })
      navigate('/login')
    }

    if (isError) {
      let message = ''
      if ((error as any).data?.errors) {
        (error as any).data.errors.forEach((errorDetails: any) => {
          const emailExistError =
            errorDetails?.message ??
            errorDetails?.error ??
            ''.includes('"email"')
          if (emailExistError) {
            toast.error(
              'Email already exists. please use different email to continue!'
            )
            message =
              'Email already exists. please use different email to continue!'
          } else {
            toast.error(errorDetails?.message ?? errorDetails?.error ?? '')
            message = errorDetails?.message ?? errorDetails?.error ?? ''
          }
        })
      } else if ((error as any).data?.error) {
        toast.error((error as any).data.error)
        message = (error as any).data.error
      } else if ((error as any).data?.message) {
        toast.error((error as any).data.message)
        message = (error as any).data.message
      } else {
        toast.error(
          'There might be some issue with the server. please try again!'
        )
        message =
          'There might be some issue with the server. please try again!'
      }
      mixpanel.track(
        'signup failed',
        { message },
        {
          transport: 'sendBeacon'
        }
      )
    }
  }, [isLoading])

  return (
    <UnauthenticatedLayout title="Signup">
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          className="mb-4"
          type="text"
          label="First Name *"
          placeholder="Enter your first name"
          {...register('first_name', {
            required: 'First name is required!',
            pattern: {
              value: /^[a-zA-Z]*$/,
              message: 'Invalid First name'
            }
          })}
          aria-invalid={errors.first_name ? 'true' : 'false'}
          error={errors.first_name}
        />

        <Input
          className="mb-4"
          type="text"
          label="Last Name *"
          placeholder="Enter your last name"
          {...register('last_name', {
            required: 'Last name is required!',
            pattern: {
              value: /^[a-zA-Z]*$/,
              message: 'Invalid Last name'
            }
          })}
          aria-invalid={errors.last_name ? 'true' : 'false'}
          error={errors.last_name}
        />

        <Input
          className="mb-4"
          type="email"
          label="Email *"
          placeholder="Enter your email"
          {...register('email', {
            required: 'Email is required!',
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: 'Invalid email'
            }
          })}
          aria-invalid={errors.email ? 'true' : 'false'}
          error={errors.email}
        />

        <Input
          className="mb-6"
          type="password"
          label="Password *"
          placeholder="Enter your password"
          {...register('password', {
            required: 'Password is required',
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
              message:
                'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number'
            }
          })}
          aria-invalid={errors.password ? 'true' : 'false'}
          error={errors.password}
        />

        <Input
          className="mb-6"
          type="password"
          label="Confirm Password *"
          placeholder="Enter your confirm password"
          {...register('confirm_password', {
            required: 'Confirm password is required',
            validate: (val: string) => {
              if (watch('password') !== val) {
                return 'Your passwords do no match!'
              }
            }
          })}
          aria-invalid={errors.confirm_password ? 'true' : 'false'}
          error={errors.confirm_password}
        />

        <Input
          className="mb-4"
          type="text"
          label="Company Name *"
          placeholder="Enter your company name"
          {...register('company_name', {
            required: 'Company Name is required!'
          })}
          aria-invalid={errors.company_name ? 'true' : 'false'}
          error={errors.company_name}
        />

        <Controller
          name="company_type"
          control={control}
          rules={{ required: 'Company Type is required!' }}
          render={({ field }) => (
            <Select
              {...field}
              className="mb-4"
              options={companyTypeOptions}
              label="Company Type *"
              clearable
              placeholder="Select company type"
              aria-invalid={errors.company_type ? 'true' : 'false'}
              error={errors.company_type}
            />
          )}
        />

        <Input
          className="mb-4"
          type="text"
          label="TD SYNNEX Account Number"
          placeholder="Enter your account number"
          {...register('account_number')}
        />

        <Input
          className="mb-4"
          type="text"
          label="Sales Representative you work with: "
          placeholder="Enter your sales representative"
          {...register('sales_representative')}
        />

        <Input
          className="mb-4"
          type="text"
          label="Welcome code if you were provided: "
          placeholder="Enter your welcome code"
          {...register('welcome_code')}
        />

        <div className="mt-5">
          <Button label="Signup" type="submit" loading={isLoading} />
        </div>

        <div className="mt-6 flex justify-center items-center">
          <p className="font-display m-0">Already have an account? </p>
          <Link
            className="inline-block align-baseline ml-1 text-black underline hover:no-underline font-display"
            to={'/login'}
          >
            Login
          </Link>
        </div>
      </form>
    </UnauthenticatedLayout>
  )
}
