import React, { FormEvent, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setQuery } from "../../reducers/searchReducer";
import { selectQuery } from "../../selectors/searchSelectors";
import { HeaderSearchForm } from "./SearchBarHeader.props";
import searchIcon from "../../assets/images/icons/icon_search.png";

export const SearchBarHeader = () => {
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const form = event.target as HTMLFormElement;
      const elements = form.elements as unknown as HeaderSearchForm;
      const searchValue = elements.search.value;
      dispatch(setQuery(searchValue));
    },
    [dispatch]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="flex justify-between gap-1 px-2 py-1 bg-transparent border-b-2 border-white w-44 h-fit sm:w-40 search-bar-header"
    >
      <label>
        <input
          type="text"
          className="w-full text-sm text-white bg-transparent placeholder:text-neutral-300 font-display"
          name="search"
          placeholder="Search Playbooks"
          defaultValue={query}
        />
      </label>
      <button onClick={() => handleSubmit}>
        <img src={searchIcon} alt="search icon" loading="lazy" />
      </button>
    </form>
  );
};
