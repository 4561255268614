import React from "react";
import { useParams } from "react-router";
import { usePageQuery } from "../../../api/api";
import { CollapseBoxList } from "../../../components/CollapseBoxList/CollapseBoxList";
import { Hero } from "../../../components/Hero/Hero";
import { HeroStyle } from "../../../components/Hero/Hero.props";
import { PostList } from "../../../components/PostList/PostList";
import { TagsList } from "../../../components/TagsList/TagsList";
import {
  extractCollapsibleTextData,
  extractHeroData,
  extractPageLinkData,
} from "../../../utils/pageDataUtils";
import "./TemplateFour.scss";
import logoPdf from "../../../assets/images/logos/logo_pdf.png";

export const TemplateFour = () => {
  const { pageId } = useParams();
  const id = parseInt(pageId ?? "", 10);
  const { currentData, isLoading, isError } = usePageQuery(id);

  if (isLoading === true || isError === true || currentData === undefined) {
    return <></>;
  }

  const heroData = extractHeroData(currentData);
  heroData.style = HeroStyle.Secondary;
  const collapseData = extractCollapsibleTextData(currentData);
  const postData = extractPageLinkData(currentData.links || []);

  return (
    <div id="ComponentExamplesUI">
      <img className="logoPdf" src={logoPdf} loading="lazy" />
      <div className="post-wrapper">
        <TagsList metadata={currentData.metadata} />
        <Hero template="four" {...heroData} />
        <CollapseBoxList boxData={collapseData} />
        <PostList template={"four"} postData={postData} />
      </div>
      <p className="pdfCopyright">
        © 2023 TD SYNNEX Corporation. All rights reserved. TD SYNNEX, the TD
        SYNNEX Logo, TECH DATA, the TD Logo, SYNNEX and the SYNNEX Logo are
        trademarks or registered trademarks of TD SYNNEX Corporation. Westcon,
        Comstor and GoldSeal are registered trademarks of WG Service Inc., used
        under license. Other names and marks are the property of their
        respective owners.
      </p>
    </div>
  );
};
