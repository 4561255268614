import React from 'react'
import './ComponentExamples.scss'
import {
  testData,
  collapseTestData,
  heroPrimaryTestData,
  heroSecondaryTestData,
  heroTertiaryTestData,
  articleListData,
  filterBarTestData,
  sidebarTitle
} from './PlaceholderData'
import { PostCard } from '../../components/PostCard/PostCard'
import { PostCardStyle } from '../../components/PostCard/PostCard.props'
import { PostList } from '../../components/PostList/PostList'
import { PostListSearch } from '../../components/PostListSearch/PostListSearch'
import { CollapseBoxList } from '../../components/CollapseBoxList/CollapseBoxList'
import { Hero } from '../../components/Hero/Hero'
import { PostSidebar } from '../../components/PostSidebar/PostSidebar'
import { FilterBar } from '../../components/FilterBar/FilterBar'
import { PostListSimple } from '../../components/PostListSimple/PostListSimple'
import { ArticleList } from '../../components/ArticleList/ArticleList'

export const ComponentExamples = () => {
  const tempPostData = [testData[0], testData[1], testData[2], testData[3]]
  const tempPostSimpleData = [
    testData[0],
    testData[1],
    testData[2],
    testData[3],
    testData[4],
    testData[5],
    testData[6],
    testData[7],
    testData[8]
  ]
  const tempSidebarData = [
    testData[0],
    testData[1],
    testData[2],
    testData[3],
    testData[4],
    testData[5],
    testData[6],
    testData[7],
    testData[8]
  ]
  const dummyNumberPage = () => {
    return 1
  }
  return (
    <div id="ComponentExamplesUI">
      <div className="post-wrapper">
        <h3>Post Example:</h3>
        <PostList template={'four'} postData={tempPostData} />
        <h3>Post Wide Example:</h3>
        <PostCard {...testData[0]} card_type={PostCardStyle.Wide} />
        <h3>Post Search Example:</h3>
        <PostListSearch
          postData={testData}
          switchCurrentPage={dummyNumberPage}
        />
        <h3>Post Simple Example:</h3>
        <PostListSimple postData={tempPostSimpleData} listTitle={'test'} />
        <h3>Hero Primary Example:</h3>
        <Hero {...heroPrimaryTestData} />
        <h3>Hero Secondary Example:</h3>
        <Hero {...heroSecondaryTestData} />
        <h3>Hero Tertiary Example:</h3>
        <Hero {...heroTertiaryTestData} />
        <h3>Article Example:</h3>
        <ArticleList articleData={articleListData} />
        <h3>Collapse List Example:</h3>
        <CollapseBoxList boxData={collapseTestData} />
        <h3>Sidebar Example:</h3>
        <PostSidebar postData={tempSidebarData} sidebarTitle={sidebarTitle} />
        <h3>Filterbar Example:</h3>
        <FilterBar {...filterBarTestData} />
      </div>
    </div>
  )
}
