import React from "react";
import "./Article.scss";
import TextTruncate from "react-text-truncate";
import { removeTags } from "../../utils/mainUtils";
import { ArticleType } from "./Article.props";
import { getAssetQuality } from "../../utils/envUtils";

export const Article = (props: ArticleType) => {
  const getTitle = () => {
    return removeTags(props.title) as string;
  };
  return (
    <div className="article">
      <div className="article-inner">
        <div
          className="article-thumbnail"
          style={{
            backgroundImage: props.thumbnail
              ? `url(${
                  props.thumbnail
                }?quality=${getAssetQuality()}&format=webp)`
              : undefined,
          }}
        ></div>
        <div className="article-content">
          <TextTruncate
            line={3}
            element="h3"
            truncateText="…"
            text={getTitle()}
          />
          <div
            className="article-content-inner"
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></div>
        </div>
      </div>
    </div>
  );
};
