import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "./PostCardMini.scss";
import arrowIcon from "../../assets/images/icons/icon_arrow.png";
import { PostType } from "../PostCard/PostCard.props";
import TextTruncate from "react-text-truncate";
import { removeTags } from "../../utils/mainUtils";
import { useTrackLinkOnClick } from "../../hooks/useTrackLinks";
import lockIcon from "../../assets/images/icons/icon_lock_white.svg";
import { Link } from "react-router-dom";
import { getAssetQuality } from "../../utils/envUtils";

export const PostCardMini = (props: PostType) => {
  const token = localStorage.getItem("p-token");
  const [data, setData] = useState<PostType>(props);

  useEffect(() => {
    setData(props);
  }, [props]);

  const getTitle = () => {
    return removeTags(data.title) as string;
  };
  const handleTrackLink = useTrackLinkOnClick("sidebar card");
  const thumbnailSrc = `${data.thumbnail}?quality=${getAssetQuality()}`;
  return (
    <div className={`post-mini ${!data.isPublic && !token ? "locked" : ""}`}>
      <Tooltip anchorSelect=".post-mini.locked" className="font-display">
        Please login to see this page.
      </Tooltip>
      {!data.isPublic && !token && (
        <div className="post-locked-icon">
          <img
            className="w-12 h-12"
            src={lockIcon}
            alt="Locked"
            loading="lazy"
          />
        </div>
      )}
      <Link
        to={data.isPublic || token ? data.permalink : ""}
        className="post-inner"
        onClick={handleTrackLink}
      >
        <span
          className="post-thumbnail"
          style={{ backgroundImage: `url(${thumbnailSrc})` }}
        ></span>
        <div className="post-content">
          <h2>
            <span>
              <TextTruncate
                line={3}
                element="span"
                truncateText="…"
                text={getTitle()}
              />
            </span>
          </h2>
          <span className="post-button">
            Learn More <img src={arrowIcon} alt="arrow icon" loading="lazy" />
          </span>
        </div>
      </Link>
    </div>
  );
};
