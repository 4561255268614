import React, { useEffect, useState } from 'react'
import './PostListSimple.scss'
import { PostListSimpleType } from './PostListSimple.props'
import { PostCardSimple } from '../PostSimple/PostCardSimple'
import classNames from 'classnames'

export const PostListSimple = (props: PostListSimpleType) => {
  const { postData, listTitle } = props
  const [posts, setPosts] = useState<any>([])
  const [viewMore, setViewMore] = useState(false)

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const [maxItems, setMaxItem] = useState(6)

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    viewportWidth >= 1200
      ? setMaxItem(6)
      : viewportWidth >= 768
        ? setMaxItem(4)
        : setMaxItem(2)
  })

  useEffect(() => {
    const postDetails = postData.map((post, i) => {
      const listItemHidden = classNames({ hidden: i >= maxItems && !viewMore })

      return (
        <li key={'search_' + post.permalink + i} className={listItemHidden}>
          <PostCardSimple {...post} />
        </li>
      )
    })
    setPosts(postDetails)
  }, [viewMore])

  const handleViewMore = () => {
    setViewMore(!viewMore)
  }
  return (
    <div className="post-list-container">
      <div className="post-wrapper">
        <h2 className="post-list-title">{listTitle}</h2>
        {posts.length ? <ul className="post-list-simple">{posts}</ul> : <></>}
        {postData.length > maxItems && (
          <button
            className={`post-list-button ${
              viewMore ? 'view-less' : 'view-more'
            }`}
            onClick={handleViewMore}
          >
            {viewMore ? 'View Less' : 'View More'}
          </button>
        )}
      </div>
    </div>
  )
}
