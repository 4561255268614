import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "./PostCard.scss";
import arrowIcon from "../../assets/images/icons/icon_arrow.png";
import { PostCardStyle, PostType } from "./PostCard.props";
import TextTruncate from "react-text-truncate";
import { removeTags } from "../../utils/mainUtils";
import classnames from "classnames";
import { useTrackLinkOnClick } from "../../hooks/useTrackLinks";
import { Link } from "react-router-dom";
import lockIcon from "../../assets/images/icons/icon_lock_white.svg";
import { getAssetQuality } from "../../utils/envUtils";

export const PostCard = (props: PostType) => {
  const token = localStorage.getItem("p-token");
  const [data, setData] = useState<PostType>(props);

  useEffect(() => {
    setData(props);
  }, [props]);

  const postClass = classnames("post", {
    wide: data.card_type === PostCardStyle.Wide,
    locked: !data.isPublic && !token,
  });
  const getTitle = () => {
    return removeTags(data.title) as string;
  };
  const getDescription = () => {
    let description = data.description;
    // If the description is empty grab the content instead
    if (description.length === 0) description = data.content;
    return removeTags(description) as string;
  };
  const getDescriptionHeight = () => {
    let height = 3;
    switch (data.card_type) {
      case PostCardStyle.Wide:
        height = 5;
        break;
      default:
        height = 3;
    }
    return height;
  };
  const handleTrackLink = useTrackLinkOnClick("link card");
  const thumbnailSrc = `${data.thumbnail}?quality=${getAssetQuality()}`;
  return (
    <div className={postClass}>
      <Tooltip anchorSelect=".post.locked" className="font-display">
        Please login to see this page.
      </Tooltip>
      {!data.isPublic && !token && (
        <div className="post-locked-icon">
          <img
            className="w-12 h-12"
            src={lockIcon}
            alt="Locked"
            loading="lazy"
          />
        </div>
      )}
      <Link
        to={data.isPublic || token ? data.permalink : ""}
        className="post-inner"
        onClick={handleTrackLink}
      >
        <div className="post-thumbnail p-3">
          <img
            className="post-thumbnail-print"
            src={thumbnailSrc}
            loading="lazy"
            alt={getTitle()}
          />
        </div>
        <div className="post-content">
          <p className="post-date">{data.post_date}</p>
          <TextTruncate
            line={3}
            element="h2"
            truncateText="…"
            text={getTitle()}
            containerClassName={"post-title"}
          />
          <h2 className={"post-title-print"}>{getTitle()}</h2>
          {/* Old non-truncated + html version. Use on the single page, not card
            <div className='post-description' dangerouslySetInnerHTML={{ __html: data.description }}></div>
          */}
          <TextTruncate
            line={getDescriptionHeight()}
            containerClassName="post-description"
            element="div"
            truncateText="…"
            text={getDescription()}
          />
          <div className={"post-description post-description-print"}>
            {getDescription()}
          </div>
          <div className="post-button">
            Learn More <img src={arrowIcon} alt="arrow icon" loading="lazy" />
          </div>
        </div>
      </Link>
    </div>
  );
};
