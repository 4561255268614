import React from "react";
import { useParams } from "react-router-dom";
import { useSearchQuery } from "../../api/api";
import { PostListSearch } from "../../components/PostListSearch/PostListSearch";
import "./PreviewPage.scss";
import { extractPageDraftData } from "../../utils/pageDataUtils";

export const PreviewPage = () => {
  const { searchValue } = useParams();
  const searchData = searchValue ?? "";

  const { currentData } = useSearchQuery({ search: searchData, metadata: {} });

  const dummyNumberPage = () => {
    return 1;
  };

  const postListData = extractPageDraftData(currentData || []);

  return (
    <>
      <div className="search-breadcrumbs">
        1-{postListData.length} OF OVER {postListData.length} RESULTS FOR{" "}
        {searchData}
      </div>
      <div className="searchResults">
        <PostListSearch
          postData={postListData}
          switchCurrentPage={dummyNumberPage}
          previewPage={true}
        />
      </div>
    </>
  );
};
