import React, { MouseEventHandler, forwardRef } from 'react'
import { LoadingIcon } from '../../assets/images/icons/icon_loading'

interface ButtonProps {
  type: 'button' | 'submit' | 'reset' | undefined;
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  invertColors?: boolean;
}

const Button = (
  { type, label, onClick, disabled, loading, invertColors }: ButtonProps,
  ref: any
) => {
  const colorClasses = `${
    invertColors ? 'bg-white text-primary' : 'bg-primary text-white'
  }`
  return (
    <button
      ref={ref}
      className={`w-full flex justify-center items-center ${colorClasses} py-3 px-8 rounded focus:outline-none focus:shadow-outline font-display`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {loading ? <LoadingIcon /> : label}
    </button>
  )
}

export default forwardRef(Button)
