import mixpanel from 'mixpanel-browser'
import { useCallback, MouseEvent } from 'react'

export const useTrackLinkOnClick = (linkLocation: string) => {
  return useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      mixpanel.track(
        'link click',
        {
          linkLocation,
          url: (event.currentTarget as HTMLAnchorElement).href
        },
        { transport: 'sendBeacon' }
      )
    },
    [linkLocation]
  )
}
