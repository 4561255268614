import React from "react";
import { PostList } from "../../../components/PostList/PostList";
import { CollapseBoxList } from "../../../components/CollapseBoxList/CollapseBoxList";
import { useParams } from "react-router";
import { usePageQuery } from "../../../api/api";
import { Hero } from "../../../components/Hero/Hero";
import {
  extractCollapsibleTextData,
  extractHeroData,
  extractPageLinkData,
} from "../../../utils/pageDataUtils";
import { HeroStyle } from "../../../components/Hero/Hero.props";

export const TemplateTwo = () => {
  const { pageId } = useParams();
  const id = parseInt(pageId ?? "", 10);
  const { currentData, isLoading, isError } = usePageQuery(id);

  if (isLoading === true || isError === true || currentData === undefined) {
    return <></>;
  }

  const heroData = extractHeroData(currentData);
  heroData.style = HeroStyle.Primary;
  const collapseData = extractCollapsibleTextData(currentData);
  const postData = extractPageLinkData(currentData.links || []);

  return (
    <div className="templateTwo">
      <div className="post-wrapper">
        <Hero template="two" {...heroData} />
        <CollapseBoxList boxData={collapseData} />
        <PostList template={"two"} postData={postData} />
      </div>
    </div>
  );
};
