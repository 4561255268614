import React, { forwardRef } from 'react'
import ReactSelect from 'react-select'

interface SelectProps {
  name: string;
  label: string;
  value?: string | number;
  placeholder: string;
  onChange?: any;
  options: Array<any>;
  disabled?: boolean;
  className?: string;
  clearable?: boolean;
  searchable?: boolean;
  loading?: boolean;
  error?: any;
}

const Select = (
  {
    name,
    label,
    value,
    placeholder,
    onChange,
    disabled,
    clearable,
    searchable,
    loading,
    className,
    options,
    error
  }: SelectProps,
  ref: any
) => {
  return (
    <div ref={ref} className={className}>
      <label className="block text-[#212529] mb-2 font-display" htmlFor={name}>
        {label}
      </label>
      <ReactSelect
        id={name}
        name={name}
        className="font-display cursor-pointer"
        classNamePrefix="react-select"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#1F728B',
            primary: '#1F728B'
          }
        })}
        defaultValue={value}
        value={value}
        isDisabled={disabled}
        isLoading={loading}
        placeholder={placeholder}
        onChange={onChange}
        isClearable={clearable}
        isSearchable={searchable}
        options={options}
      />

      {error && (
        <p className="text-danger-500 mt-1 font-display text-xs">
          {error?.message}
        </p>
      )}
    </div>
  )
}

export default forwardRef(Select)
