import React, { ChangeEventHandler, forwardRef } from 'react'

interface InputProps {
  name: string;
  type: string;
  label?: string;
  value?: string | number;
  placeholder: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  required?: boolean;
  error?: any;
}

const Input = ({
  name,
  type,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  readOnly,
  required,
  className,
  error
}: InputProps, ref: any) => {
  return (
    <div ref={ref} className={className}>
      <label className="block text-[#212529] mb-2 font-display" htmlFor={name}>
        {label}
      </label>
      <input
        className="bg-secondary h-14 appearance-none border rounded w-full py-2 px-3 text-gray-700 border-none outline-none font-display"
        id={name}
        name={name}
        aria-label={name}
        data-testid={name}
        tabIndex={0}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
      />

      {error && <p className='text-danger-500 mt-1 font-display text-xs'>{error?.message}</p>}
    </div>
  )
}

export default forwardRef(Input)
