import React from 'react'
import { useTrackLinkOnClick } from '../../hooks/useTrackLinks'
import './FooterLinks.scss'

export const FooterLinks = () => {
  const handleTrackLink = useTrackLinkOnClick('footer')
  return (
    <div className="footer-links">
      <ul>
        <li>
          <a
            href="https://www.tdsynnex.com/na/us/terms-conditions/"
            target="_blank"
            rel="nofollow noreferrer"
            className="font-display"
            onClick={handleTrackLink}
          >
            Terms & Services
          </a>
        </li>
        <li>
          <a
            href="https://www.tdsynnex.com/na/us/privacy/"
            target="_blank"
            rel="nofollow noreferrer"
            className="font-display"
            onClick={handleTrackLink}
          >
            Privacy
          </a>
        </li>
      </ul>
      <p className="m-0 font-display mt-2">
        © {new Date().getFullYear()} TD SYNNEX Corporation. All rights reserved.
      </p>
    </div>
  )
}
