import React, { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import './PostCardSearch.scss'
import arrowIcon from '../../assets/images/icons/icon_arrow.png'
import { PostType } from '../PostCard/PostCard.props'
import TextTruncate from 'react-text-truncate'
import { removeTags } from '../../utils/mainUtils'
import { useTrackLinkOnClick } from '../../hooks/useTrackLinks'
import lockIcon from '../../assets/images/icons/icon_lock_white.svg'
import { Link } from 'react-router-dom'

export const PostCardSearch = (props: PostType) => {
  const token = localStorage.getItem('p-token')
  const [data, setData] = useState<PostType>(props)

  useEffect(() => {
    setData(props)
  }, [props])

  const link = `${data.permalink}${data.previewLink ? '?preview' : ''}`
  const getTitle = () => {
    return removeTags(data.title) as string
  }
  const handleTrackLink = useTrackLinkOnClick('search')
  const transformedDescription = data.description
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, ' ')
  return (
    <div className={`post-search ${!data.isPublic && !token ? 'locked' : ''}`}>
      <Tooltip anchorSelect=".post-search.locked" className='font-display'>
        Please login to see this page.
      </Tooltip>
      {!data.isPublic && !token && (
        <div className="post-locked-icon">
          <img className="w-12 h-12" src={lockIcon} alt="Locked" loading="lazy" />
        </div>
      )}
      <Link
        to={data.isPublic || token ? link : ''}
        className="post-inner"
        onClick={handleTrackLink}
      >
        <div
          className="post-thumbnail"
          style={{ backgroundImage: `url(${data.thumbnail})` }}
        ></div>
        <div className="post-content">
          <TextTruncate
            line={3}
            element="h2"
            truncateText="…"
            text={getTitle()}
          />
          <div className="post-description">
            <TextTruncate
              line={3}
              element="p"
              truncateText="…"
              text={transformedDescription}
            />
          </div>
          <span className="post-button">
            Learn More <img src={arrowIcon} alt="arrow icon" loading="lazy" />
          </span>
        </div>
      </Link>
    </div>
  )
}
