import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { SearchState } from '../reducers/searchReducer'
import { RootState } from '../store'

const selectSelf = (state: RootState) => state
export const selectSearch = createDraftSafeSelector(
  selectSelf,
  (state: RootState): SearchState => state.search
)
export const selectFilter = createDraftSafeSelector(
  selectSearch,
  (search: SearchState) => search.filter
)
export const selectQuery = createDraftSafeSelector(
  selectSearch,
  (search: SearchState) => search.query
)
