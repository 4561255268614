import React from "react";
import "./TagsList.scss";
import { Metadata } from "../../api/page.types";
import { getAssetQuality, getDirectusBaseUrl } from "../../utils/envUtils";
import { PrintButton } from "../PrintButton/PrintButton";

export const TagsList = (props: { metadata?: Metadata }) => {
  const { metadata } = props;
  const basePath = `${getDirectusBaseUrl()}assets/`;

  const tags = !metadata
    ? []
    : Object.entries(metadata).map(([groupName, tagsList]) => {
        return tagsList
          .filter((tag) => tag.icon !== undefined)
          .map((tag, i) => {
            return (
              <img
                key={i}
                src={`${
                  basePath + tag.icon
                }?quality=${getAssetQuality()}&format=webp`}
                alt={tag.displayName}
                title={tag.displayName}
                className="tag"
                loading="lazy"
              />
            );
          });
      });

  return (
    <div className="tags-list">
      {tags.length ? tags : <></>}
      <PrintButton />
    </div>
  );
};
