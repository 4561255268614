import React from 'react'
import './CollapseBoxList.scss'
import { CollapseBoxListType } from './CollapseBoxList.props'
import { CollapseBox } from '../CollapseBox/CollapseBox'

export const CollapseBoxList = (props: CollapseBoxListType) => {
  const boxes = props.boxData.map((box) => (
    <li key={'main_' + box.title}>
      <CollapseBox {...box} />
    </li>
  ))
  return boxes.length ? <ul className="collapse-box-list">{boxes}</ul> : <></>
}
