import React from "react";
import { PostList } from "../../../components/PostList/PostList";
import { CollapseBoxList } from "../../../components/CollapseBoxList/CollapseBoxList";
import { useParams } from "react-router";
import { usePageQuery } from "../../../api/api";
import { Hero } from "../../../components/Hero/Hero";
import { PostSidebar } from "../../../components/PostSidebar/PostSidebar";
import "./TemplateThree.scss";
import {
  extractCollapsibleTextData,
  extractHeroData,
  extractPageLinkData,
} from "../../../utils/pageDataUtils";
import { HeroStyle } from "../../../components/Hero/Hero.props";

export const TemplateThree = () => {
  const { pageId } = useParams();
  const id = parseInt(pageId ?? "", 10);
  const { currentData, isLoading, isError } = usePageQuery(id);

  if (isLoading === true || isError === true || currentData === undefined) {
    return <></>;
  }

  const heroData = extractHeroData(currentData);
  heroData.style = HeroStyle.Primary;
  const collapseData = extractCollapsibleTextData(currentData);
  const postData = extractPageLinkData(currentData.links || []);
  const sidebarData = extractPageLinkData(currentData.sidebarLinks || []);
  const sidebarTitle = currentData.sidebarTitle || "";

  return (
    <div className="templateThree">
      <div className="post-wrapper">
        <Hero template="three" {...heroData} />
        <CollapseBoxList boxData={collapseData} />
        <PostList template={"three"} postData={postData} />
      </div>
      {sidebarData.length ? (
        <div className="side-bar">
          <PostSidebar postData={sidebarData} sidebarTitle={sidebarTitle} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
