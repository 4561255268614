import React, { useEffect, useState } from 'react'
import './PostListSearch.scss'
import { PostCardSearch } from '../PostCardSearch/PostCardSearch'
import { PostListSearchType } from './PostListSearch.props'
import { PostPagination } from '../PostPagination/PostPagination'
import { PostType } from '../PostCard/PostCard.props'

export const PostListSearch = (props: PostListSearchType) => {
  const [numberPerPage] = useState(15)
  const getNumberOfChunkPages = () => {
    const postChunks = props.postData.filter((post, index) => {
      return index % numberPerPage === 0
    })
    return postChunks.length
  }

  const getPostChunks = (currentIndex: number) => {
    const currentChunks: PostType[] = []
    const postOffset = numberPerPage * currentIndex
    for (
      let postIndex = postOffset;
      postIndex < postOffset + numberPerPage;
      postIndex++
    ) {
      if (props.postData[postIndex]) {
        currentChunks.push(props.postData[postIndex])
      }
    }
    return currentChunks
  }
  const [chunkIndex, setChunkIndex] = useState(0)
  const [numberOfChunkPages, setNumberOfChunkPages] = useState(0)
  const [currentChunks, setCurrentChunks] = useState(getPostChunks(chunkIndex))

  useEffect(() => {
    setCurrentChunks(getPostChunks(chunkIndex))
    setNumberOfChunkPages(getNumberOfChunkPages())
    props.switchCurrentPage(chunkIndex + 1)
  }, [chunkIndex, props.postData])

  const posts = currentChunks.map((post, i) => (
    <li key={'search_' + post.permalink + i}>
      <PostCardSearch previewLink={props.previewPage} {...post} />
    </li>
  ))

  return (
    <div className="post-search-container container">
      <ul className="post-search-list">{posts}</ul>
      {props.postData.length > numberPerPage && (
        <PostPagination
          numberOfPages={numberOfChunkPages}
          startingIndex={chunkIndex}
          handlePagination={setChunkIndex}
        />
      )}
    </div>
  )
}
