import React, { useState } from 'react'
import './PostPagination.scss'
import {
  PaginationDirectionType,
  PostPaginationType
} from './PostPagination.props'
import { wrappingMod } from '../../utils/mathUtils'

export const PostPagination = (props: PostPaginationType) => {
  const [currentIndex, setCurrentIndex] = useState(props.startingIndex)
  const handlePagination = (
    type: PaginationDirectionType,
    directIndex: number = 0
  ) => {
    if (type === PaginationDirectionType.Direct) {
      setCurrentIndex(directIndex)
      props.handlePagination(directIndex)
    } else if (type === PaginationDirectionType.Relative) {
      if (
        currentIndex + directIndex + 1 > props.numberOfPages ||
        currentIndex + directIndex < 0
      ) { return }

      const newIndex = wrappingMod(
        currentIndex + directIndex,
        props.numberOfPages
      )
      setCurrentIndex(newIndex)
      props.handlePagination(newIndex)
    }
  }

  const createPaginationButtons = () => {
    const paginationElements = []
    for (let chunkIndex = 0; chunkIndex < props.numberOfPages; chunkIndex++) {
      let buttonClass = ''
      if (currentIndex === chunkIndex) buttonClass = 'active'
      paginationElements.push(
        <li key={'pagination_item_' + chunkIndex}>
          <button
            className={buttonClass}
            onClick={() =>
              handlePagination(PaginationDirectionType.Direct, chunkIndex)
            }
          >
            {chunkIndex + 1}
          </button>
        </li>
      )
    }
    return (
      <ul>
        <li>
          <button
            className="active"
            onClick={() =>
              handlePagination(PaginationDirectionType.Relative, -1)
            }
          >
            ◀
          </button>
        </li>
        {paginationElements}
        <li>
          <button
            className="active"
            onClick={() =>
              handlePagination(PaginationDirectionType.Relative, 1)
            }
          >
            ▶
          </button>
        </li>
      </ul>
    )
  }
  return <div className="post-pagination">{createPaginationButtons()}</div>
}
