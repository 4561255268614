import React from "react";
import errorIcon from "../../assets/images/icons/icon_error.svg";
import { UnauthenticatedLayout } from "../UnauthenticatedLayout/UnauthenticatedLayout";
import Button from "../../components/Form/Button";

export const UnauthorizedPage = () => {
  const redirectToMainPage = () => {
    window.location.href = window.location.origin;
  };

  return (
    <UnauthenticatedLayout title="Authorization Required!">
      <div className="flex flex-col justify-center items-center text-center">
        <img
          className="w-12 h-12 mb-4"
          src={errorIcon}
          alt="Error"
          loading="lazy"
        />
        <p className="m-0 font-display text-lg mb-10">
          You are trying to access a page that is not authorized for you. please
          click on Explore Now to go back.
        </p>
        <Button
          type="button"
          label="Explore Now"
          onClick={redirectToMainPage}
        />
      </div>
    </UnauthenticatedLayout>
  );
};
