import React, { useEffect, useState } from 'react'
import { useSendVerifyEmailMutation } from '../../api/auth'
import Button from '../../components/Form/Button'
import { toast } from 'react-hot-toast'
import mixpanel from 'mixpanel-browser'

export const VerifyEmailBar = ({ email }: any) => {
  const [sent, setSent] = useState(false)
  const [show, setShow] = useState(true)
  const [sendVerifyEmail, { isLoading, isError, isSuccess, error }] =
    useSendVerifyEmailMutation()

  const send = () => {
    sendVerifyEmail({ email })
  }

  useEffect(() => {
    if (isSuccess) {
      setSent(true)
      toast.success(
        'Verification email has been sent. please check your email.'
      )
      mixpanel.track(
        'Email verification success',
        {},
        { transport: 'sendBeacon' }
      )
    }

    if (isError) {
      let message = ''
      if ((error as any).data?.errors) {
        (error as any).data.errors.forEach((errorDetails: any) => {
          toast.error(errorDetails?.message ?? errorDetails?.error ?? '')
          message = errorDetails?.message ?? errorDetails?.error ?? ''
        })
      } else if ((error as any).data?.error) {
        toast.error((error as any).data.error)
        message = (error as any).data.error
      } else if ((error as any).data?.message) {
        toast.error((error as any).data.message)
        message = (error as any).data.message
      } else {
        toast.error(
          'There might be some issue with the server. please try again!'
        )
        message =
          'There might be some issue with the server. please try again!'
      }
      mixpanel.track(
        'Email verification failed',
        { message },
        {
          transport: 'sendBeacon'
        }
      )
    }
  }, [isLoading])

  return (
    <>
      {show && (
        <div className="px-6 py-3 bg-warning-500 flex flex-nowrap items-center border-2 border-white rounded-lg">
          <div className="text-left">
            <h4 className="text-white text-xl font-bold font-display">
              Email not verified.
            </h4>
            <p className="text-white text-lg font-display m-0">
              {sent
                ? (
                <>
                  <span>
                    We&apos;ve sent you an email on
                    <b className="text-white"> {email}</b> to verify your
                    account.
                  </span>

                  <span className="block">
                    Click on &quot;Resend Email&quot; button if you&apos;ve not
                    received the verification email.
                  </span>
                </>
                  )
                : (
                <span>
                  Please click on &quot;Send Email&quot; button to get a
                  verification email in your email address:{' '}
                  <b className="text-white"> {email}</b>.
                </span>
                  )}
            </p>
          </div>
          <div className="ml-auto flex">
            <Button
              label={sent ? 'Resend Email' : 'Send Email'}
              type="button"
              onClick={send}
              loading={isLoading}
              invertColors
            />

            <div className="ml-3">
              <Button
                label="Cancel"
                type="button"
                invertColors
                onClick={() => setShow(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
