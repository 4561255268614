import { ArticleType } from '../../components/Article/Article.props'
import { CollapseBoxType } from '../../components/CollapseBox/CollapseBox.props'
import { FilterBarType } from '../../components/FilterBar/FilterBar.props'
import {
  HeroStyle,
  HeroThumbnailStyle,
  HeroType
} from '../../components/Hero/Hero.props'
import { PostType } from '../../components/PostCard/PostCard.props'

const testData: PostType[] = [
  {
    id: 1,
    title: 'This is an example of a post title one',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_1',
    post_date: 'Date of Post'
  },
  {
    id: 2,
    title: 'This is an example of a post title two',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_2',
    post_date: 'Date of Post'
  },
  {
    id: 3,
    title: 'This is an example of a post title three',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_3]',
    post_date: 'Date of Post'
  },
  {
    id: 4,
    title: 'This is an example of a post title four',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_4',
    post_date: 'Date of Post'
  },
  {
    id: 5,
    title: 'This is an example of a post title five',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_5',
    post_date: 'Date of Post'
  },
  {
    id: 6,
    title: 'This is an example of a post title six',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_6',
    post_date: 'Date of Post'
  },
  {
    id: 7,
    title: 'This is an example of a post title seven',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_7]',
    post_date: 'Date of Post'
  },
  {
    id: 8,
    title: 'This is an example of a post title eight',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_8',
    post_date: 'Date of Post'
  },
  {
    id: 9,
    title: 'This is an example of a post title nine',
    description: '',
    thumbnail: 'https://place-hold.it/500x300',
    thumbnail_circle: 'https://place-hold.it/500x500',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    permalink: '[POST_URL]_9',
    post_date: 'Date of Post'
  }
]

const collapseTestData: CollapseBoxType[] = [
  {
    title: 'Target Audience',
    content:
      '<p>Check out this list:</p><ul><li>Are you interested in generating new revenue streams?</li><li>How are you responding to COVID-19?</li><li>What does your current strategy for monitoring chronic patients look like today?</li><li>What’s your telehealth strategy?</li></ul>'
  },
  {
    title: 'Customer Challenges',
    content:
      '<p>Check out this list:</p><ul><li>Are you interested in generating new revenue streams?</li><li>How are you responding to COVID-19?</li><li>What does your current strategy for monitoring chronic patients look like today?</li><li>What’s your telehealth strategy?</li></ul>'
  },
  {
    title: 'Key Words To Listen For',
    content:
      '<p>Check out this list:</p><ul><li>Are you interested in generating new revenue streams?</li><li>How are you responding to COVID-19?</li><li>What does your current strategy for monitoring chronic patients look like today?</li><li>What’s your telehealth strategy?</li></ul>'
  }
]

const heroPrimaryTestData: HeroType = {
  title: 'Compliance Requirements',
  content:
    '<p>HIPAA policy, including the threat of data breaches, may always be at the forefront of compliance concerns for the healthcare industry - and rightfully so. However, there are an increasing number of technological advancements playing a role in disrupting healthcare delivery and access that garner attention too. Often, solutions stem from the need to meet compliance. These analytics and IoT solutions are no exception.</p>',
  style: HeroStyle.Primary,
  thumbnail: 'https://place-hold.it/500x500',
  thumbnailStyle: HeroThumbnailStyle.Circle,
  updatedDate: '2023-04-12T19:17:54.692Z'
}

const heroSecondaryTestData: HeroType = {
  title: 'Compliance Requirements',
  content:
    '<p>HIPAA policy, including the threat of data breaches, may always be at the forefront of compliance concerns for the healthcare industry - and rightfully so. However, there are an increasing number of technological advancements playing a role in disrupting healthcare delivery and access that garner attention too. Often, solutions stem from the need to meet compliance. These analytics and IoT solutions are no exception.</p>',
  style: HeroStyle.Secondary,
  thumbnail: 'https://place-hold.it/500x500',
  thumbnailStyle: HeroThumbnailStyle.Regular,
  updatedDate: '2023-04-12T19:17:54.692Z'
}

const heroTertiaryTestData: HeroType = {
  title: 'Solution Catalog',
  subTitle: 'PLAYBOOK',
  postDate: 'April 2023',
  logo: 'https://place-hold.it/200x60',
  content:
    '<p>Short Description of playbook name that doesn’t got past 5 sentences. ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaeca</p>',
  style: HeroStyle.Tertiary,
  thumbnail: 'https://place-hold.it/500x500',
  thumbnailStyle: HeroThumbnailStyle.Regular,
  updatedDate: '2023-04-12T19:17:54.692Z'
}

const articleListData: ArticleType[] = [
  {
    title: 'Confidentiality Notice',
    description:
      '<p>The information contained in this document is confidential and is intended only to be viewed by the recipient(s). The recipient(s) is not authorized to share this document without written permission from the sender. If you are not the intended recipient(s), you are hereby notified that any distribution or copying of this document is strictly prohibited</p>',
    thumbnail: 'https://place-hold.it/500x500'
  },
  {
    title: 'Playbook Purpose',
    description:
      '<p>This is a self-service playbook for TD SYNNEX solutions, designed to inform TD SYNNEX sellers and TD SYNNEX channel partners of the analytics and internet of things (IoT) solutions available to them. This playbook offers a one-page overview of each solution along with links to additional materials to best equip resellers in the market.</p>',
    thumbnail: 'https://place-hold.it/500x500'
  }
]

const filterBarTestData: FilterBarType = {
  fromMetadata: {
    vendor: [
      { displayName: 'Intel' },
      { displayName: 'Microsoft' },
      { displayName: 'HP' }
    ],
    country: [{ displayName: 'USA' }, { displayName: 'EMEIA' }],
    complexity: [
      { displayName: 'Low Complexity' },
      { displayName: 'Medium Complexity' },
      { displayName: 'High Complexity' }
    ]
  },
  isLoading: false
}

const sidebarTitle = 'Sidebar title'

export {
  testData,
  collapseTestData,
  heroPrimaryTestData,
  heroSecondaryTestData,
  heroTertiaryTestData,
  articleListData,
  filterBarTestData,
  sidebarTitle
}
