import {
  CollapsibleText,
  DirectusLinkedPage,
  DirectusPageData,
  DirectusTag,
  DirectusTagLink,
  LinkedCollapsibleText,
  LinkedPage,
  PageData,
  Tag,
} from "./page.types";

export const getPageUrl = (pageId: number): string => {
  const path = "items/Page";
  const fieldNames = [
    "*",
    "tags.*",
    "tags.Tag_id.*",
    "tags.Tag_id.icon.*",
    "tags.Tag_id.tagGroup.*",
    "Hero_Image.*",
    "Thumbnail.*",
    "Logo.*",
    "Background.*",
    "Links.*",
    "Links.related_Page_id.*",
    "Links.related_Page_id.tags.*",
    "Links.related_Page_id.Hero_Image.*",
    "Links.related_Page_id.Thumbnail.*",
    "Links.related_Page_id.Logo.*",
    "Links.related_Page_id.Background.*",
    "Sidebar_Links.*",
    "Sidebar_Links.related_Page_id.*",
    "Sidebar_Links.related_Page_id.tags.*",
    "Sidebar_Links.related_Page_id.Hero_Image.*",
    "Sidebar_Links.related_Page_id.Thumbnail.*",
    "Sidebar_Links.related_Page_id.Logo.*",
    "Sidebar_Links.related_Page_id.Background.*",
    "Articles.*",
    "Articles.related_Page_id.*",
    "Articles.related_Page_id.tags.*",
    "Articles.related_Page_id.Hero_Image.*",
    "Articles.related_Page_id.Thumbnail.*",
    "Articles.related_Page_id.Logo.*",
    "Articles.related_Page_id.Background.*",
    "Collapsible_Texts.Collapsible_Text_id.*",
    "breadcrumbs.id",
    "breadcrumbs.breadcrumbs_page_id.*",
    "tenants.*",
    "Tags.*",
  ];
  const fields = `fields[]=${fieldNames.join(",")}&limit=-1`;
  const filter = pageId ? `filter[id]=${pageId}` : "";

  return `${path}?${fields}&${filter}`;
};

export const getLandingPageUrl = (): string => {
  const path = "items/Landing_Page";
  const fieldNames = [
    "*",
    "Page.*",
    "Page.tags.*",
    "Page.tags.Tag_id.*",
    "Page.tags.Tag_id.icon.*",
    "Page.tags.Tag_id.tagGroup.*",
    "Page.Hero_Image.*",
    "Page.Thumbnail.*",
    "Page.Logo.*",
    "Page.Background.*",
    "Page.Links.*",
    "Page.Links.related_Page_id.*",
    "Page.Links.related_Page_id.tags.*",
    "Page.Links.related_Page_id.Hero_Image.*",
    "Page.Links.related_Page_id.Thumbnail.*",
    "Page.Links.related_Page_id.Logo.*",
    "Page.Links.related_Page_id.Background.*",
    "Page.Sidebar_Links.*",
    "Page.Sidebar_Links.related_Page_id.*",
    "Page.Sidebar_Links.related_Page_id.tags.*",
    "Page.Sidebar_Links.related_Page_id.Hero_Image.*",
    "Page.Sidebar_Links.related_Page_id.Thumbnail.*",
    "Page.Sidebar_Links.related_Page_id.Logo.*",
    "Page.Sidebar_Links.related_Page_id.Background.*",
    "Page.Articles.*",
    "Page.Articles.related_Page_id.*",
    "Page.Articles.related_Page_id.tags.*",
    "Page.Articles.related_Page_id.Hero_Image.*",
    "Page.Articles.related_Page_id.Thumbnail.*",
    "Page.Articles.related_Page_id.Logo.*",
    "Page.Articles.related_Page_id.Background.*",
    "Page.tenants.*",
    "Page.Collapsible_Texts.Collapsible_Text_id.*",
    "Page.subpages.*",
  ];
  const fields = `fields[]=${fieldNames.join(",")}`;

  return `${path}?${fields}`;
};

const defaultThumbnail = "";

export const transformDirectusLinkedPages = (
  pages: LinkedPage[] | number[]
): PageData[] => {
  return pages
    ? (pages
        .map((linkedPage) => {
          if (typeof linkedPage === "number") {
            return undefined;
          }
          if (linkedPage.related_Page_id === null) {
            return undefined;
          }
          return transformDirectusPage(linkedPage.related_Page_id);
        })
        .filter(Boolean) as PageData[])
    : [];
};

export const transformDirectusCollapsibleTexts = (
  collapsibleTexts: LinkedCollapsibleText[] | number[]
): CollapsibleText[] => {
  return collapsibleTexts
    ? (collapsibleTexts
        .map((collapsibleText) => {
          if (
            typeof collapsibleText === "number" ||
            !collapsibleText.Collapsible_Text_id
          ) {
            return undefined;
          }

          return {
            title: collapsibleText.Collapsible_Text_id.Title,
            description: collapsibleText.Collapsible_Text_id.Description,
            status: collapsibleText.Collapsible_Text_id.status,
          };
        })
        .filter(Boolean) as CollapsibleText[])
    : [];
};

export const transformDirectusTag = (tag: DirectusTag): Tag => {
  return {
    displayName: tag.displayName,
    icon: tag.icon?.id,
    sort: tag.sort,
  };
};

export const transformDirectusTags = (tags: DirectusTagLink[]): any => {
  return tags
    ? tags
        ?.filter((tag) => tag?.Tag_id?.tagGroup?.groupName)
        ?.reduce((obj, tag) => {
          let tagGroup = obj[tag.Tag_id.tagGroup.groupName];
          if (!tagGroup) {
            tagGroup = obj[tag.Tag_id.tagGroup.groupName] = [];
          }
          tagGroup.push(transformDirectusTag(tag.Tag_id));

          return obj;
        }, {} as Record<string, Tag[]>)
    : [];
};

const filterPublishedData = (rawData: PageData[]) => {
  return rawData.filter((obj) => obj.status === "published");
};

const filterPublishedCollapsibleTexts = (rawData: CollapsibleText[]) => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has("preview")) {
    return rawData;
  }
  return rawData.filter((obj) => obj.status === "published");
};

export const transformDirectusPage = (
  pageData: DirectusPageData | DirectusLinkedPage
): PageData => {
  const sidebarLinks = filterPublishedData(
    transformDirectusLinkedPages(pageData?.Sidebar_Links)
  );
  const collapsibleTexts = filterPublishedCollapsibleTexts(
    transformDirectusCollapsibleTexts(pageData?.Collapsible_Texts)
  );
  const articles = filterPublishedData(
    transformDirectusLinkedPages(pageData?.Articles)
  );
  const links = filterPublishedData(
    transformDirectusLinkedPages(pageData?.Links)
  );
  const metadata = transformDirectusTags(pageData?.tags);

  return {
    id: pageData?.id,
    title: pageData?.Title,
    linkTitle: pageData?.Link_Title || pageData?.Title,
    description: pageData?.Description,
    shortDescription: pageData?.Short_Description || pageData?.Description,
    thumbnail: pageData?.Thumbnail
      ? pageData.Thumbnail.id
      : pageData?.Hero_Image
      ? pageData.Hero_Image.id
      : defaultThumbnail,
    templateType: pageData?.Template_Type,
    heroImage: pageData?.Hero_Image?.id,
    heroImageType: pageData?.Hero_Image?.type,
    heroImageQuality: pageData?.Hero_Image_Quality,
    useCircleImage: pageData?.Use_Circle_Image,
    logo: pageData?.Logo?.id,
    background: pageData?.Background?.id,
    listTitle: pageData?.List_Title,
    sidebarTitle: pageData?.Sidebar_Title,
    sidebarLinks,
    collapsibleTexts,
    articles,
    links,
    metadata,
    status: pageData?.status,
    isPublic: pageData?.isPublic,
    updatedDate: pageData?.date_updated,
    breadcrumbs: pageData?.breadcrumbs.map((b) => ({
      id: b.id,
      label: b.breadcrumbs_page_id.Title,
      slug: b.breadcrumbs_page_id.id.toString(),
    })),
    tenants: pageData?.tenants
      ? pageData.tenants.map((tenant) => tenant.tenants_id)
      : [],
  };
};
