import React, { FormEvent, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Tag } from '../../api/page.types'
import { setFilter } from '../../reducers/searchReducer'
import { createEmptyMetadata } from '../../utils/metadataUtils'
import { FilterBarSection } from '../FilterBarSection/FilterBarSection'
import { FilterBarType } from './FilterBar.props'
import './FilterBar.scss'

export const FilterBar = (props: FilterBarType) => {
  const dispatch = useDispatch()
  const sections =
    Object.entries(props.fromMetadata).length === 0
      ? !props.isLoading && <p>No filters to be applied</p>
      : Object.entries(props.fromMetadata)
        .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
        .map(([name, tags], i) => {
          const options = tags?.map?.((tag: Tag) => ({
            title: tag.displayName,
            value: tag.displayName
          }))

          return (
              <FilterBarSection key={name + i} title={name} options={options} />
          )
        })

  const handleChange = useCallback((event: FormEvent<HTMLFormElement>) => {
    const emptyMetadata = createEmptyMetadata()
    const selectedMetadata = Array.from(event.currentTarget.elements).reduce(
      (metadata, el) => {
        const input = el as HTMLInputElement
        if (input.type !== 'checkbox') return metadata

        if (input.checked) {
          let tagGroup = metadata[input.name]
          if (!tagGroup) {
            tagGroup = metadata[input.name] = []
          }
          tagGroup.push({ displayName: input.value })
        }

        return metadata
      },
      emptyMetadata
    )

    dispatch(setFilter(selectedMetadata))
  }, [])

  return (
    <form className="filter-bar" onChange={handleChange}>
      <div className="filter-bar-header">
        <h3>Filter Playbooks</h3>
      </div>
      <div className="filter-bar-sections">{sections}</div>
    </form>
  )
}
