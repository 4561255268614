import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getDirectusBaseUrl } from '../utils/envUtils'
import { containsMetadata } from '../utils/metadataUtils'
import { getLandingPageUrl, getPageUrl, transformDirectusPage } from './page'
import {
  DirectusPageDataResponse,
  PageData,
  Metadata,
  DirectusLandingPageDataResponse
} from './page.types'
import { getSearchUrl } from './search'

export const api = createApi({
  reducerPath: 'cmsApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: getDirectusBaseUrl(),
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('p-token')
      if (token) headers.set('Authorization', `Bearer ${token}`)
      return headers
    }
  }),
  endpoints: (build) => ({
    page: build.query<PageData, any>({
      query: getPageUrl,
      transformResponse: (response: DirectusPageDataResponse) => {
        return transformDirectusPage(response?.data?.[0])
      }
    }),
    search: build.query<PageData[], { search: string; metadata?: Metadata }>({
      query: getSearchUrl,
      transformResponse: (
        response: DirectusPageDataResponse,
        extra,
        { search, metadata }
      ) => {
        const pages = response?.data?.map(transformDirectusPage)
        const filteredPages = pages.filter((page) =>
          containsMetadata(page?.metadata, metadata)
        )
        return filteredPages
      }
    }),
    landingPage: build.query<PageData, void>({
      query: getLandingPageUrl,
      transformResponse: (response: DirectusLandingPageDataResponse) => {
        return transformDirectusPage(response?.data?.Page)
      }
    })
  })
})

export const { usePageQuery, useSearchQuery, useLandingPageQuery } = api
