import React from 'react'
import './ArticleList.scss'
import { ArticleListType } from './ArticleList.props'
import { Article } from '../Article/Article'

export const ArticleList = (props: ArticleListType) => {
  const posts = props.articleData.map((article, i) => (
    <li key={'article_' + article.title + i}>
      <Article {...article} />
    </li>
  ))
  return (
    <>{posts.length ? <ul className="article-list">{posts}</ul> : <></>}</>
  )
}
