import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { BreadcrumbProps } from "../../api/page.types";
import { usePageQuery } from "../../api/api";
import BreadcrumbItem, { ChevronRight, MoreIcon } from "./Item";

const Breadcrumbs = () => {
  const params = useParams();
  const [rest, setRest] = useState<(BreadcrumbProps | undefined)[]>([]);
  const [first, setFirst] = useState<BreadcrumbProps>();
  const [last, setLast] = useState<BreadcrumbProps>();
  const [pageId, setPageId] = useState<string | undefined>("");
  const { currentData } = usePageQuery(pageId);

  useEffect(() => {
    setPageId(params.pageId);
  }, [params.pageId]);

  useEffect(() => {
    if (currentData) {
      const data = [...currentData.breadcrumbs];

      setFirst(data[0]);
      setLast({
        id: currentData.id.toString(),
        label: currentData.title,
        slug: currentData.id.toString(),
      });

      const restData = data
        .map((b, i) => (i !== 0 ? b : undefined))
        .filter((b) => b !== undefined);

      setRest(restData);
    }
  }, [currentData]);

  return (
    <div className="flex flex-wrap items-center bg-white border-b border-primary">
      <ul className="flex items-center flex-wrap w-full max-w-[1440px] mx-auto pl-5 md:px-7 lg:px-12 py-3">
        {/* Home */}
        <BreadcrumbItem
          breadcrumb={{
            id: "",
            label: "Home",
            slug: "",
          }}
          theme="primary"
        />

        {/* First */}
        {first && (
          <>
            <ChevronRight />
            <BreadcrumbItem breadcrumb={first} theme="secondary" />
          </>
        )}

        {/* More */}
        {rest && rest.length > 0 && (
          <>
            <ChevronRight />
            <Popup
              trigger={
                <div className="cursor-pointer" title="Click for more">
                  <MoreIcon />
                </div>
              }
              position={["bottom center"]}
              closeOnDocumentClick
            >
              <div className="flex flex-col divide-y divide-slate-200">
                {rest.map((breadcrumb) => (
                  <Link
                    to={`/${breadcrumb?.slug}`}
                    className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[400px] hover:text-white hover:bg-primary outline-none p-3 text-left"
                  >
                    <span>{breadcrumb?.label}</span>
                  </Link>
                ))}
              </div>
            </Popup>
          </>
        )}

        {/* Last */}
        {last && (
          <>
            <ChevronRight />
            <BreadcrumbItem breadcrumb={last} theme="primary" />
          </>
        )}
      </ul>
    </div>
  );
};
export default Breadcrumbs;
