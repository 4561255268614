import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import Input from '../../components/Form/Input'
import Button from '../../components/Form/Button'
import { useResetPasswordMutation } from '../../api/auth'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout/UnauthenticatedLayout'
import mixpanel from 'mixpanel-browser'

export const ChangePasswordPage = () => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [resetPassword, { isLoading, isError, error, isSuccess }] =
    useResetPasswordMutation()

  const submitForm = async (values: any) => {
    const token = searchParams.get('token')
    await resetPassword({
      token,
      password: values.password
    })
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Your password has been changed successfully.')
      mixpanel.track(
        'Change password success',
        {},
        { transport: 'sendBeacon' }
      )
      navigate('/login')
    }

    if (isError) {
      let message = ''
      if ((error as any).data?.errors) {
        (error as any).data.errors.forEach((errorDetails: any) => {
          toast.error(errorDetails?.message ?? errorDetails?.error ?? '')
          message = errorDetails?.message ?? errorDetails?.error ?? ''
        })
      } else if ((error as any).data?.error) {
        toast.error((error as any).data.error)
        message = (error as any).data.error
      } else if ((error as any).data?.message) {
        toast.error((error as any).data.message)
        message = (error as any).data.message
      } else {
        toast.error(
          'There might be some issue with the server. please try again!'
        )
        message =
          'There might be some issue with the server. please try again!'
      }
      mixpanel.track(
        'Change password failed',
        { message },
        {
          transport: 'sendBeacon'
        }
      )
    }
  }, [isLoading])

  return (
    <UnauthenticatedLayout title="Change Password">
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          className="mb-6"
          type="password"
          label="Password *"
          placeholder="Enter your password"
          {...register('password', {
            required: 'Password is required',
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
              message:
                'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number'
            }
          })}
          aria-invalid={errors.password ? 'true' : 'false'}
          error={errors.password}
        />

        <Input
          className="mb-6"
          type="password"
          label="Confirm Password *"
          placeholder="Enter your password"
          {...register('confirm_password', {
            required: 'Password is required',
            validate: (val: string) => {
              if (watch('password') !== val) {
                return 'Your passwords do no match!'
              }
            }
          })}
          aria-invalid={errors.confirm_password ? 'true' : 'false'}
          error={errors.confirm_password}
        />

        <div className="mt-5">
          <Button label="Change Password" type="submit" />
        </div>
      </form>
    </UnauthenticatedLayout>
  )
}
