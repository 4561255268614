import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Metadata } from '../api/page.types'
import {
  getStoredFilter,
  getStoredQuery,
  setStoredFilter,
  setStoredQuery
} from '../utils/storageUtils'
import { createEmptyMetadata } from '../utils/metadataUtils'
import mixpanel from 'mixpanel-browser'
import { encodeURL } from 'js-base64'
import { withBaseUrl } from '../utils/withBaseUrl'

export interface SearchState {
  filter: Metadata;
  query: string;
}

const initialState: SearchState = {
  filter: getStoredFilter(),
  query: getStoredQuery()
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<Metadata>) => {
      state.filter = action.payload
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
      state.filter = createEmptyMetadata()
    }
  }
})

export const { setFilter, setQuery } = searchSlice.actions

export const searchReducer = searchSlice.reducer

const buildSearchUrl = (searchState: SearchState): string => {
  const query = encodeURL(searchState.query)
  const queryObj = Object.fromEntries(
    Object.entries(searchState.filter).map(([tagGroup, tags]) => [
      tagGroup,
      tags.map((tag) => tag.displayName).join(',')
    ])
  )
  const filterQueryParams = new URLSearchParams(queryObj)

  return withBaseUrl(`/search/${query}?${filterQueryParams.toString()}`)
}

export const searchListener = {
  actionCreator: setQuery,
  effect: (action: PayloadAction<string>, listenerApi: any) => {
    setStoredQuery(action.payload)
    setStoredFilter(createEmptyMetadata())
    const searchState = listenerApi.getState().search
    mixpanel.track('Search', { search: searchState }, { transport: 'sendBeacon' })
    window.location.href = buildSearchUrl(searchState)
  }
}

export const filterListener = {
  actionCreator: setFilter,
  effect: (action: PayloadAction<Metadata>, listenerApi: any) => {
    setStoredFilter(action.payload)
    const searchState = listenerApi.getState().search
    mixpanel.track('Search', { search: searchState }, { transport: 'sendBeacon' })
    window.location.href = buildSearchUrl(searchState)
  }
}
