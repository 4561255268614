import {
  combineReducers,
  configureStore,
  createListenerMiddleware,
  PreloadedState,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { api } from "./api/api";
import { authApi } from "./api/auth";
import { userApi } from "./api/user";
import { menuApi } from "./api/menu";
import {
  searchReducer,
  searchListener,
  filterListener,
} from "./reducers/searchReducer";
import userReducer from "./reducers/userReducer";
import menuReducer from "./reducers/menuReducer";

const rootReducer = combineReducers({
  search: searchReducer,
  user: userReducer,
  menu: menuReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [api.reducerPath]: api.reducer,
  [menuApi.reducerPath]: menuApi.reducer,
});

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening(searchListener);
listenerMiddleware.startListening(filterListener);

export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .concat([
          api.middleware,
          authApi.middleware,
          userApi.middleware,
          menuApi.middleware,
        ]),
    preloadedState,
  });
};

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const store = setupStore();
