import { Metadata, PageData, Tag } from "../api/page.types";
import { ArticleType } from "../components/Article/Article.props";
import { CollapseBoxType } from "../components/CollapseBox/CollapseBox.props";
import {
  HeroStyle,
  HeroThumbnailStyle,
  HeroType,
} from "../components/Hero/Hero.props";
import { PostType } from "../components/PostCard/PostCard.props";
import { getDirectusBaseUrl } from "./envUtils";
import defaultCard from "../assets/images/logos/card_logo_default.png";

export const extractHeroData = (currentData: PageData): HeroType => {
  const title = currentData?.title ?? "";
  const shortDescription = currentData?.shortDescription ?? "";
  const content = currentData?.description ?? "";
  const basePath = `${getDirectusBaseUrl()}assets/`;
  const logo = currentData?.logo ? `${basePath}${currentData?.logo}` : "";
  const thumbnail = currentData?.heroImage
    ? `${basePath}${currentData?.heroImage}`
    : currentData?.background
    ? `${basePath}${currentData?.background}`
    : null;
  const thumbnailStyle = currentData?.useCircleImage
    ? HeroThumbnailStyle.Circle
    : HeroThumbnailStyle.Regular;
  const thumbnailQuality = currentData?.heroImageQuality;
  const thumbnailType = currentData?.heroImageType;
  const updatedDate = currentData?.updatedDate || "";

  const extractedHeroData: HeroType = {
    title,
    shortDescription,
    content,
    logo,
    thumbnailStyle,
    thumbnailQuality,
    thumbnailType,
    style: HeroStyle.Primary,
    updatedDate,
  };
  if (thumbnail) extractedHeroData.thumbnail = thumbnail;

  return extractedHeroData;
};

export const extractCollapsibleTextData = (
  currentData: PageData
): CollapseBoxType[] => {
  if (currentData?.collapsibleTexts === undefined) {
    return [];
  }

  return currentData?.collapsibleTexts?.map((collapsibleText) => ({
    title: collapsibleText.title || "",
    content: collapsibleText.description || "",
  }));
};

export const extractPageLinkData = (pageLinks: PageData[]): PostType[] => {
  return pageLinks
    .filter(
      (pageLink) =>
        pageLink.status === "published" && pageLink.templateType !== "article"
    )
    .map((pageLink) => ({
      id: pageLink.id,
      title: pageLink.linkTitle || "",
      description: pageLink.shortDescription || "",
      thumbnail: getDataThumbnail(pageLink.thumbnail, pageLink.heroImage),
      thumbnail_circle: getDataThumbnail(
        pageLink.thumbnail,
        pageLink.heroImage
      ),
      content: pageLink.description ?? "",
      permalink: "/" + (pageLink.id || ""),
      isPublic: pageLink.isPublic,
      post_date: "Date of Post",
    }));
};

export const extractPageDraftData = (pageLinks: PageData[]): PostType[] => {
  return pageLinks
    .filter((pageLink) => pageLink.status === "draft")
    .map((pageLink) => ({
      id: pageLink.id,
      title: pageLink.linkTitle || "",
      description: pageLink.shortDescription || "",
      thumbnail: getDataThumbnail(pageLink.thumbnail, pageLink.heroImage),
      thumbnail_circle: getDataThumbnail(
        pageLink.thumbnail,
        pageLink.heroImage
      ),
      content: pageLink.description ?? "",
      permalink: "/" + (pageLink.id || ""),
      isPublic: pageLink.isPublic,
      post_date: "Date of Post",
    }));
};

const getDataThumbnail = (thumbnail?: string, hero?: string): string => {
  if ((!thumbnail && !hero) || thumbnail?.includes(".mp4")) {
    return defaultCard;
  } else if (thumbnail) {
    return `${getDirectusBaseUrl()}assets/${thumbnail}`;
  } else {
    return `${getDirectusBaseUrl()}assets/${hero}`;
  }
};

export const extractArticleData = (currentData: PageData): ArticleType[] => {
  return (
    currentData?.articles?.map((article) => ({
      title: article.title || "",
      description: article.description || "",
      thumbnail: getDataThumbnail(article.thumbnail || ""),
    })) || []
  );
};

export const extractUniqueMetadata = (data?: PageData[]): Metadata => {
  const uniqueMetadata =
    data?.reduce((obj, page) => {
      if (!page.metadata) return obj;

      Object.entries(page.metadata).forEach(([groupName, tagList]) => {
        let tagGroup = obj[groupName];
        if (!tagGroup) {
          tagGroup = obj[groupName] = {};
        }
        tagList.forEach((tag) => {
          tagGroup[tag.displayName] = tag;
        });
      });

      return obj;
    }, {} as Record<string, Record<string, Tag>>) || {};

  const sorted = Object.fromEntries(
    Object.entries(uniqueMetadata).map(([groupName, uniqueTags]) => [
      groupName,
      Object.values(uniqueTags),
    ])
  );

  Object.entries(sorted).forEach(([groupName, tagList]) => {
    tagList.sort((a: Tag, b: Tag) =>
      a.sort && b.sort ? a.sort - b.sort : a.sort ? 1 : -1
    );
  });

  return sorted;
};
