import { Metadata } from '../api/page.types'

export const createEmptyMetadata = (): Metadata => ({})

// returns true if a contains all of the tags that b has.
export const containsMetadata = (a?: Metadata, b?: Metadata): boolean => {
  if (b === undefined) return true

  return Object.entries(b as Metadata).every(([tagGroup, tags]) =>
    tags.every((tag) =>
      a?.[tagGroup]?.find((pageTag) => pageTag.displayName === tag.displayName)
    )
  )
}
